export class TranslateMdl {
  Gym: string="";
  FootBall: string = "";
  Thefieldofinhibitions: string = "";
  ruggeddrivingranges: string = "";
  Trainingfields: string = "";
  Services: string = "";
  Lecturehalls: string = "";
  CenterParts: string = "";
  Partenrs: string = "";
  SuccessStories: string = "";
  Mission: string = "";
  AboutUs: string = "";
  Login: string = "";
  Home: string = "";
  ReserchesStudies: string = "";
  learners: string = "";
  ContactUs: string = "";
  News: string = "";
  Events: string = "";
  Cources: string = "";
  A1Title: string = "";
  A1: string = "";
  A2Title: string = "";
  A2: string = "";
  A3Title: string = "";
  A3: string = "";
  A4Title: string = "";
  A4: string = "";
  UserName: string = "";
  Password: string = "";
  ForgetPassword: string = "";
  Email: string = "";
  ThankYouPasswordReset: string = "";
  ResetPassowrd: string = "";
  ThankYouEmailDone: string = "";
  SelcCountry: string = "";
  Thanks: string = "";
  YouUserNameIS: string = "";
  success: string = "";
  error: string = "";
  FillAllData: string = "";
  FileExtError: string = "";
  info: string = "";
  library: string = "";
  Vision: string = "";
  Partners: string = "";
  FactFiguers: string = "";
  NewsEvents : string = "";
  Researches: string = "";
  Studies: string = "";
  Newsletter : string = "";
  Publications : string = "";
  PressRealese : string = "";
  Media: string = "";
  ContactUsError: string = "";
  Requierd : string = "";
  Calendar: string = "";
  Submit: string;
  EmptyMsg: string;
  McqList: string;
  Exam: string;
  SubjectName: string;
  Facilities: string;
}
