import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { StepState } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material';

import { APICallsService } from '../../Services/apicalls.service';
import { Student } from '../../Models/Student.model';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { Router } from '@angular/router';
import { FileUpload } from '../../Models/Common/FileUpload';
import { MessageService } from 'primeng/api';
import { CustomValidators } from '../../Services/Common/CustomValidator';
import { CommonService } from '../../Services/Common/common.service';
import { TranslateServiceAPI } from '../../Services/Translation/translateAPI.service';

export class StepData {
  StepIndx: number;
  StepTitel: string;
  StepDesc: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [MessageService]
})




export class RegisterComponent implements OnInit {

  firstFormGroup: FormGroup;

  secondFormGroup: FormGroup;
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;


  AllSteps: StepData[] = [];
  totalStepsCount: number = this.AllSteps.length;
  selectedStep: StepData = new StepData();

  SaveTxt: string = "Submit Data";



  registerForm: FormGroup;
  get f() { return this.registerForm.controls; }
  submitted = true;
  Student: Student = new Student();
  ngOnInit() {
    debugger;
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });



    let sing: StepData = new StepData();
    sing.StepIndx = 0;
    sing.StepTitel = "PERSONAL INFORMATION";
    sing.StepDesc = "Write All Your Personal Data & To Be Correct";
    this.AllSteps.push(sing);

    sing = new StepData();
    sing.StepIndx = 1;
    sing.StepTitel = "CONTACT INFORMATION";
    sing.StepDesc = "Write All Your Personal Data";
    this.AllSteps.push(sing);

    sing = new StepData();
    sing.StepIndx = 2;
    sing.StepTitel = "PASSPORT AND TRAVEL INFORMATION";
    sing.StepDesc = "Write All Passport Data";
    this.AllSteps.push(sing);

    sing = new StepData();
    sing.StepIndx = 3;
    sing.StepTitel = "EMERGENCY CONTACT INFORMATION";
    sing.StepDesc = "Write All Your Personal Data";
    this.AllSteps.push(sing);

    sing = new StepData();
    sing.StepIndx = 4;
    sing.StepTitel = "Attachemnt";
    sing.StepDesc = "Please add 3 Attachment";
    this.AllSteps.push(sing);

    sing = new StepData();
    sing.StepIndx = 5;
    sing.StepTitel = "Save Your Data";
    sing.StepDesc = "If All Data Is Valid please Submit";
    this.AllSteps.push(sing);

    //sing = new StepData();
    //sing.StepIndx = 6;
    //sing.StepTitel = "MCQ Test";
    //sing.StepDesc = "You Should Answer All Questions";
    //this.AllSteps.push(sing);


    this.API.GetCounty().subscribe(
      Data => {
        console.log('test');
        this.ListOfConunty = Data;


      },
      err => {
        console.log(err);
      });

  }

  constructor(private _formBuilder: FormBuilder, private API: APICallsService, private router: Router
    , private MessageService: MessageService, private Translate: TranslateServiceAPI
    , public CommonService: CommonService) {


    this.registerForm = this._formBuilder.group({
      fname: ['', [Validators.required, this.noWhitespaceValidator, this.HasNumber]],
      midname: ['', [Validators.required, this.noWhitespaceValidator, this.HasNumber]],
      lastName: ['', [Validators.required, this.noWhitespaceValidator, this.HasNumber]],
      genderid: [1, [Validators.required]],
      counteryid: ['', [Validators.required]],
      zipcode: ['', [Validators.required, this.noWhitespaceValidator]],
      birthdate: ['', [Validators.required, this.DateIsAsMin]],
      officeemail: ['', [Validators.required, Validators.email, this.noWhitespaceValidator]],
      personalemail: ['', [Validators.required, Validators.email, this.noWhitespaceValidator]],
      officephone: ['', [Validators.required, this.noWhitespaceValidator]],
      homephone: ['', [Validators.required, this.noWhitespaceValidator]],
      mobilephone: ['', [Validators.required, this.noWhitespaceValidator]],
      passportnumber: ['', [Validators.required, this.noWhitespaceValidator]],
      issuedby: ['', [Validators.required, this.noWhitespaceValidator, this.HasNumber]],
      passportname: ['', [Validators.required, this.noWhitespaceValidator, this.HasNumber]],
      issuedate: ['', [Validators.required, this.noWhitespaceValidator]],
      expiredate: ['', [Validators.required, this.noWhitespaceValidator]],

      emergencycontct: ['', [Validators.required, this.noWhitespaceValidator, this.HasNumber]],
      emergencyreklation: ['', [Validators.required, this.noWhitespaceValidator, this.HasNumber]],
      emergencytel: ['', [Validators.required, this.noWhitespaceValidator]],
      password:
        new FormControl('',
          [
            Validators.required,
            Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
          ]),
      confirmpassword: new FormControl('', [Validators.required]),
      file1: ['',],
      file2: ['',],
      file3: ['',],
      files: ['',],
      skills: [''],
      experience: ['',],
      IsUser: [true],
      //attachtwo: ['', [Validators.required]],
      //attachthree: ['', [Validators.required]],
      //password: ['', [Validators.required, Validators.minLength(6)]],
      //confirmPassword: ['', Validators.required],
      //acceptTerms: [false, Validators.requiredTrue]
    },
      {
        // check whether our password and confirm password match
        validator: [CustomValidators.passwordMatchValidator]
      });

  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  //minDate = "2000-01-01";
  maxDate = "2000-01-01";


  ISLoading: boolean = false;
  LoadingMsg: string = "Submitting Data....";

  public DateIsAsMin(control: FormControl) {

    var USerDate = new Date(control.value);
    var NowDate = new Date();
    var Time = USerDate.getTime() - NowDate.getTime();
    var Days = Time / (1000 * 3600 * 24) * -1; //Diference in Days

    const isOver20Year = (Days / 365) <= 20;

    return isOver20Year ? { AgeIsGood: true } : null;;

  }

  public HasNumber(control: FormControl) {
    const hasNumeric = /[0-9]+/.test(control.value);

    return hasNumeric ? { TextHasNumber: true } : null;

  }

  onSubmit() {
    this.submitted = true;


    console.log(this.registerForm);
    // stop here if form is invalid
    if (this.registerForm.valid) {
      this.ISLoading = true;
      //this.SaveTxt = this.Translate.instant("FillAllData");
      // display form values on success
      //Save
      this.registerForm.controls["files"].setValue(this.attach);
      this.API.RegStudent(this.registerForm.value, this.attach).subscribe(
        Data => {
          let Thanks = this.CommonService.CurrentLang.Thanks
          let YouUserNameIS = this.CommonService.CurrentLang.YouUserNameIS;
          let Msg = Thanks + " " + this.f.fname.value + " " + YouUserNameIS + " " + this.f.officeemail.value;
          if (Data.success) {
           // this.registerForm.reset();
            this.MessageService.add({
              severity: "success", summary: this.CommonService.CurrentLang.success,
              detail: Msg
            })
            this.CommonService.GoToInter(this.router, '/MakeMCQ')
          } else {
            this.MessageService.add({
              severity: Data.notificationType, summary: this.CommonService.CurrentLang.info,
              detail: Data.msg
            })
          }

          this.ISLoading = false;
        },
        err => {
          this.MessageService.add({
            severity: "error", summary: this.CommonService.CurrentLang.error,
            detail: this.CommonService.CurrentLang.error
          })
          this.ISLoading = false;
        });
      // End
    }
    else {

      this.MessageService.add({
        severity: "error", summary: this.CommonService.CurrentLang.error, detail: this.CommonService.CurrentLang.FillAllData
      })
      //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    }


  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  attach: FileUpload[] = [new FileUpload, new FileUpload, new FileUpload];



  fileToUpload: File = null;
  FileUploadName: string;
  showFileUploadName: Boolean;
  AddFile(event, InputCount, Ext: string[]) {
    try {
      this.attach[InputCount]=null;
    } catch (err) {

    }
    this.fileToUpload = <File>event.target.files[0];
    if (this.validateFile(this.fileToUpload.name.toString(), Ext)) {
      this.FileUploadName = this.fileToUpload.name.toString();
      this.showFileUploadName = true;
      this.AddFileToFileArr(InputCount);
    } else {
      this.fileToUpload = null;
      event.target.value = null;
      this.MessageService.add({
        severity: "error", summary: this.CommonService.CurrentLang.error, detail: this.CommonService.CurrentLang.FileExtError + " " + JSON.stringify(Ext)
      });
    }
   
  }

  AddingToArr = false;
  AddFileToFileArr(InputCount) {
    let reader = new FileReader();
    let _this = this;
    this.AddingToArr = true;
    reader.onload = (function (theFile) {
      return function (e) {
        let binaryString = e.target.result.substring(e.target.result.indexOf("base64,") + 7);
        _this.attach[InputCount]={
          FakeId: _this.attach.length + 1,
          file: null,
          fullImage: e.target.result,
          fileName: _this.fileToUpload.name,
          fileString: binaryString,
          fromServer: false
        };
        _this.AddingToArr = false;
      };
    })(this.fileToUpload);
    reader.readAsDataURL(this.fileToUpload)
  }
  validateFile(name: string, Ext: string[]) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (Ext.find(x => x.toLowerCase() == ext.toLowerCase())) {
      return true;
    }
    else {
      return false;
    }
  }

  ListOfConunty: any[] = [];

  focusOutFunction() {
    console.log(this.registerForm);
  }

  ngAfterViewInit() {
    this.totalStepsCount = this.myStepper._steps.length;
    this.myStepper.selectedIndex = 0;
    this.selectedStep = this.AllSteps[0];
  }

  current_step: number = 0;
  goBack(stepper: MatStepper) {
    stepper.previous();
    this.current_step = this.current_step - 1;
    this.myStepper.selectedIndex = this.current_step;
    this.selectedStep = this.AllSteps[this.current_step];
  }
  goForward(stepper: MatStepper) {
    //if (this.registerForm.invalid) {
    //  return;
    //}
    //else {
    stepper.next();
    this.current_step = this.current_step + 1;
    this.myStepper.selectedIndex = this.current_step;
    this.selectedStep = this.AllSteps[this.current_step];
    // }
    //console.log($("#Cnt").innerHeight());
  }







}
