import { Byte } from "@angular/compiler/src/util";

export class FileUpload {
  fileString: string;
  fileName: string;
  file: Byte[];
  FakeId: number;
  id?: number;
  fullImage: string;
  fromServer?: boolean = false;
}
