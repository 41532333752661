<app-top-nav-bar></app-top-nav-bar>

<section id="hero">
  <div class="hero-container mb-5" style="top: 15vh;" data-aos="zoom-in" data-aos-delay="100">
    <section class="mx-auto mb-5 container-fluid" style="height:100%;">
      <ul class="carousel-thumbnail">
        <li class="carousel-thumbnail__item list-inline-item" *ngFor="let img of Elm?.photos; index as i" (click)="goToSlide(img.id)">
          <a class="selected">
            <img class="carousel-thumbnail__img" *ngIf="IsImage(img.fileName)" [src]="img.fileName">
            <img class="carousel-thumbnail__img" *ngIf="!IsImage(img.fileName)" src="assets/img/ico-video.webp">
          </a>
        </li>
      </ul>
      <ngb-carousel style="height:100%;" class="MyItem " [activeId]="activeSliderId" #carousel [interval]="100000" [pauseOnHover]="true" [pauseOnFocus]="true" (slide)="onSlide($event)">
        <ng-template ngbSlide *ngFor="let img of Elm?.photos; index as i" style="height:100%;" [id]="img.id">
          <!--<div class="carousel-caption">
        <h3>My slide {{i + 1}} title</h3>
      </div>-->
          <div class="picsum-img-wrapper fill" style="height:100%;">
            <img *ngIf="IsImage(img.fileName)"  class="mt-3" [src]="img.fileName">
            <video *ngIf="!IsImage(img.fileName)"  width="480" height="640" controls>
              <source src="{{img.fileName}}" type="video/mp4">
              <source src="{{img.fileName}}" type="video/ogg">
              Your browser does not support the video tag.
            </video>
          </div>
        </ng-template>

      </ngb-carousel>

    </section>

    <!--<section class="container">

      <section class="col-12">
        <h4 style="color:#ffffff;" class="" *ngIf="ShowTitle">{{Elm?.title}}</h4>
        <hr />
      </section>
      <section class="col-12" style="color:#ffffff;overflow-y:scroll;height:300px;">
        <div style="padding-bottom:60px;" *ngIf="ShowBody" [innerHtml]="Elm?.body"></div>
      </section>
    </section>-->

  </div>


</section><!-- End Hero Section -->
