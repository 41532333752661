
<header id="header" class="fixed-top d-flex align-items-center header-transparent">

  <div class="container-fluid px-6 d-flex justify-content-between align-items-center">

    <div id="logo" style="padding:0px">
      <div class="row" >
        <div class="col-md-2" >
          <a [routerLink]="['/']"><img src="assets/img/logo.png" style="width: 120px; height: 120px; margin-top: 40px;"></a>
        </div>
        <div class="col-md-10" style="padding: 55px;margin-top: 8px;">
          <div >
            <a style="color:white;font-size:larger"><strong>المركز المصري للتدريب على عمليات حفظ السلام </strong>  </a>
          </div>
          <div class="row">
            <a style="color: white; font-size: medium"><strong>Egyptian PeaceKeeping Operation Training Center</strong>  </a>
          </div>
        </div>
      </div>
     


      <!--Uncomment below if you prefer to use a text logo
      <h1><a href="index.html">Regna</a></h1>-->
    </div>
    <div class="clearfix">
      <div class="spinner-border float-right text-warning" role="status" *ngIf="!IsDone">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <nav id="navbar" class="navbar navbar-expand-lg navbar-light ">

      <button (click)="openNav()" class="navbar-toggler NavButn{{Show}}" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse " id="navbarSupportedContent" [ngClass]="Show?'show':''">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <div class="search-container">
              <input class="search expandright" id="searchright" type="search" name="q"
                     placeholder="Search" (keyup.enter)="Search($event.target.value)" #SearchBox />
              <label class="button searchbutton" for="searchright"><span class="mglass">&#9906;</span></label>
            </div>
          </li>
          <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/',langKey]">{{CommonService.CurrentLang.Home}}</a></li>
          <li class="nav-item dropdown">
            <a><span>{{CommonService.CurrentLang.AboutUs}}</span> <i class="bi bi-chevron-down"></i></a>
            <ul class="list-unstyled MyUl">
              <li><a (click)="GoToCenterPart('Vision')">{{CommonService.CurrentLang.Vision}}</a></li>
              <li><a (click)="GoToCenterPart('Mission')">{{CommonService.CurrentLang.Mission}}</a></li>
              <li><a (click)="GoToCenterPart('Facilities')">{{CommonService.CurrentLang.Facilities}}</a></li>
              <li><a (click)="GoToCenterPart('Partners')">{{CommonService.CurrentLang.Partners}}</a></li>
              <li><a (click)="GoToCenterPart('FactFiguers')">{{CommonService.CurrentLang.FactFiguers}}</a></li>

              <li><a (click)="GoToCenterPart('FactFiguers')">{{CommonService.CurrentLang.Calendar}}</a></li>

              <!--<li><a (click)="GoToCenterPart('RuggedDrivingRanges')"> {{CommonService.CurrentLang.ruggeddrivingranges}} </a></li>
  <li><a (click)="GoToCenterPart('inhibitions')"> {{CommonService.CurrentLang.Thefieldofinhibitions}} </a></li>
  <li><a (click)="GoToCenterPart('FootBall')"> {{CommonService.CurrentLang.FootBall}} </a></li>
  <li><a (click)="GoToCenterPart('Gym')"> {{CommonService.CurrentLang.Gym}} </a></li>-->

            </ul>
          </li>
          <li class="nav-item dropdown">
            <a><span>{{CommonService.CurrentLang.Publications}}</span> <i class="bi bi-chevron-down"></i></a>
            <ul class="list-unstyled MyUl">
              <li><a class="" (click)="GoToCenterPart('Researches')">{{CommonService.CurrentLang.Researches}}</a></li>
              <li><a class="" (click)="GoToCenterPart('Studies')">{{CommonService.CurrentLang.Studies}}</a></li>
              <li><a class="" (click)="GoToCenterPart('Newsletter')">{{CommonService.CurrentLang.Newsletter}}</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a><span>{{CommonService.CurrentLang.NewsEvents}}</span> <i class="bi bi-chevron-down"></i></a>
            <ul class="list-unstyled MyUl">
              <li><a class="" (click)="GoToCenterPart('PressRealese')">{{CommonService.CurrentLang.PressRealese}}</a></li>
              <li><a class="" [routerLink]="['/',langKey,'media']">{{CommonService.CurrentLang.Media}}</a></li>
              <li><a class="" [routerLink]="['/',langKey,'news']">{{CommonService.CurrentLang.News}}</a></li>
              <li><a class="" [routerLink]="['/',langKey,'events']">{{CommonService.CurrentLang.Events}}</a></li>
            </ul>
          </li>


          <!--<li class="nav-item"><a class="nav-link btn" *ngIf="IsLogin!='true'" (click)="LoginVisable=true">{{CommonService.CurrentLang.Login}}</a></li>-->
          <li class="nav-item" *ngIf="IsLogin!='true'"><a class="nav-link btn" [routerLink]="['/Register',langKey]">{{CommonService.CurrentLang.StudentRegistration}}</a></li>
          <!--<li class="nav-item" *ngIf="IsLogin=='true'"><a class="nav-link btn" [routerLink]="['/']">{{CommonService.CurrentLang.MyCourses}}</a></li>-->
          <li class="nav-item" *ngIf="IsLogin=='true'"><a class="nav-link btn" [routerLink]="['/',langKey,'McqList']">{{CommonService.CurrentLang.McqList}}</a></li>
          <li class="nav-item" *ngIf="IsLogin=='true'"><a class="nav-link btn" (click)="LogOut()">{{CommonService.CurrentLang.LogOut}}</a></li>


          <!--<li class="nav-item"><a class="nav-link scrollto" href="/{{langKey}}#call-to-action">{{CommonService.CurrentLang.CenterParts}}</a></li>-->
          <!--<li class="dropdown">
    <a href="#"><span>{{'Cources'|translate}}</span> <i class="bi bi-chevron-down"></i></a>
    <ul>
      <li><a [routerLink]="['/CourecView']"> training plan</a></li>
      <li><a href="#">Join Cource</a></li>
      <li><a [routerLink]="['/MakeMCQ']">Make MCQ Test</a></li>
    </ul>
  </li>-->
          <!--<li class="dropdown">
    <a href="#"><span>{{'ReserchesStudies'|translate}}</span> <i class="bi bi-chevron-down"></i></a>
    <ul>
      <li><a href="#"> Peacekeeping Studies</a></li>
      <li><a href="#">Various studies and research</a></li>
    </ul>
  </li>-->
          <!--<li class="dropdown">
    <a href="#"><span>{{'learners'|translate}}</span> <i class="bi bi-chevron-down"></i></a>
    <ul>
      <li><a [routerLink]="['/Register']">Students registration</a></li>

    </ul>
  </li>-->

          <!--<li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/',langKey,'Calendar']">{{CommonService.CurrentLang.Calendar}}</a></li>-->
          <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/',langKey,'ContactUs']" >{{CommonService.CurrentLang.ContactUs}}</a></li>

          <li class="nav-item">
            <div class="col">
              <div dropdown class="d-inline-block">
                <!--<button class="btn" id="dropdownBasic1" >{{langKey}}<img style="width:25px;" src="{{Flag}}"></button>-->
                <div dropdown-menu aria-labelledby="dropdownBasic1">
                  <button dropdown-item class="en" (click)="ChangeLang('en','../../../../assets/img/england.ico')">EN <img style="width:25px;" src="../../../../assets/img/england.ico"></button>
                  <button dropdown-item class="ar" (click)="ChangeLang('ar','../../../../assets/img/Egypt.ico')">AR <img style="width:25px;" src="../../../../assets/img/Egypt.ico"></button>
                  <button dropdown-item class="fn" (click)="ChangeLang('fn','../../../../assets/img/france.ico')">FR <img style="width:25px;" src="../../../../assets/img/france.ico"></button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav><!-- .navbar -->
    <div id="Sidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <div class="col">
            <div ngbDropdown class="d-inline-block">
              <button class="btn" id="dropdownBasic1" ngbDropdownToggle>{{langKey}}<img style="width:25px;" src="{{Flag}}"></button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem class="en" (click)="ChangeLang('en','../../../../assets/img/england.ico')">en <img style="width:25px;" src="../../../../assets/img/england.ico"></button>
                <button ngbDropdownItem class="ar" (click)="ChangeLang('ar','../../../../assets/img/Egypt.ico')">ar <img style="width:25px;" src="../../../../assets/img/Egypt.ico"></button>
                <button ngbDropdownItem class="fn" (click)="ChangeLang('fn','../../../../assets/img/france.ico')">fn <img style="width:25px;" src="../../../../assets/img/france.ico"></button>
              </div>
            </div>

          </div>
        </li>
        <li class="nav-item"><a class="nav-link scrollto" href="#hero">{{CommonService.CurrentLang.Home}}</a></li>
        <li class="nav-item"><a class="nav-link scrollto" href="#hero">{{CommonService.CurrentLang.Login}}</a></li>
        <li class="nav-item dropdown">
          <a href="#"><span>{{CommonService.CurrentLang.AboutUs}}</span> <i class="bi bi-chevron-down"></i></a>
        <li class="nav-item"><a class="nav-link scrollto" href="#contact">{{CommonService.CurrentLang.ContactUs}}</a></li>

      </ul>
    </div>
  </div>
</header><!-- End Header -->

<p-dialog [modal]="true" [(visible)]="LoginVisable" [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}"
          [draggable]="false" [resizable]="false" #LoginDialoge>
  <form [formGroup]="LoginForm">
    <div class="container">
      <div class="row ">
        <div class="col-sm-12 ">
          <div class="form-group">
            <label class="col-form-label" for="inputDefault">{{CommonService.CurrentLang.UserName}}</label>
            <input type="text" class="form-control" placeholder="{{CommonService.CurrentLang.UserName}}"
                   [ngClass]="{'is-invalid':LoginForm.get('username').invalid && LoginForm.get('username').touched}"
                   formControlName="username">
          </div>
          <div class="form-group">
            <label class="col-form-label" for="inputDefault">{{CommonService.CurrentLang.Password}}</label>
            <input [type]="TogglePass ? 'password' : 'text'" class="form-control"
                   placeholder="{{CommonService.CurrentLang.Password}}"
                   [ngClass]="{'is-invalid':LoginForm.get('password').invalid && LoginForm.get('password').touched}"
                   formControlName="password">
            <div [ngClass]="{'d-none':TogglePass}" (click)="TogglePass=!TogglePass" style="position: absolute; z-index: 1; cursor: pointer; margin-right: 4%; right: 4%; top: 53%; ">
              <i class="bi bi-eye-fill"></i>
            </div>
            <div [ngClass]="{'d-none':!TogglePass}" class="icon" (click)="TogglePass=!TogglePass" style="position: absolute; z-index: 1; cursor: pointer; margin-right: 4%; right: 4%; top: 53%; ">
              <i class="bi bi-eye-slash-fill"></i>
            </div>
          </div>
          <div class="form-group mt-5 col-12">
            <button class="btn btn-success m-auto col-12" (click)="Login()" #LoginBtn>{{CommonService.CurrentLang.Login}}</button>
          </div>
        </div>
        <div>
          <button class="btn-link" (click)="LoginVisable=false;ForgetModel=true;">{{CommonService.CurrentLang.ForgetPassword}}</button>
        </div>
        <!--<h6 *ngIf="WrongUserName" class="text-danger m-auto col-12 mt-5">
          Wrong User Name Or Password
        </h6>-->
      </div>
    </div>
  </form>
</p-dialog>
<p-dialog [(visible)]="ForgetModel" [modal]="true" [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}"
          [draggable]="false" [resizable]="false">
  <form [formGroup]="ForgetForm">
    <div class="container">
      <div class="row ">
        <div class="col-sm-12 ">
          <div class="form-group">
            <label class="col-form-label" for="inputDefault">{{CommonService.CurrentLang.Email}}</label>
            <input type="email" class="form-control"
                   placeholder="{{CommonService.CurrentLang.Email}}"
                   [ngClass]="{'is-invalid':ForgetForm.get('Email').invalid && ForgetForm.get('Email').touched}"
                   formControlName="Email" />
            <p class="text-danger mt-2 clearfix" *ngIf="ForgetForm.get('Email').errors?.pattern&& ForgetForm.get('Email').touched">Email is not valid / البريد  غير صحيح</p>
          </div>
          <div class="form-group col-12">
            <button class="btn btn-success m-auto col-12" (click)="ResetPassword()" #ResetBtn>{{CommonService.CurrentLang.ResetPassowrd}}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<p-dialog [(visible)]="ThankYouModel" [modal]="true" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}"
          [draggable]="false" [resizable]="false">
  <form [formGroup]="ForgetForm">
    <div class="container">
      <div class="row ">
        <div class="col-sm-12 ">
          <p [innerHtml]="CommonService.CurrentLang.ThankYouEmailDone"></p>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<p-toast></p-toast>
