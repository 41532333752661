import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './Sys/SetUp/register/register.component';
import { AppComponent } from './app.component';
import { APP_BASE_HREF } from '@angular/common';
import { LandingComponent } from './Sys/SetUp/landing/landing.component';
import { AddMSQComponent } from './Sys/Operation/add-msq/add-msq.component';
import { ViewMCQComponent } from './Sys/Operation/view-mcq/view-mcq.component';
import { CourcesViewComponent } from './Sys/Operation/cources-view/cources-view.component';
import { ListComponent } from './Sys/SetUp/list/list.component';
import { DetailsComponent } from './Sys/SetUp/details/details.component';
import { DoResetPasswordComponent } from './Sys/SetUp/do-reset-password/do-reset-password.component';
import { CalenderViewComponent } from './Sys/Operation/calender-view/calender-view.component';
import { MediaComponent } from './Sys/SetUp/media/media.component';
import { ContactusComponent } from './Sys/SetUp/contactus/contactus.component';
import { CalenderComponent } from './Sys/SetUp/calender/calender.component';
import { McqOnlineListComponent } from './Sys/SetUp/mcq-online-list/mcq-online-list.component';
import { McqExamComponent } from './Sys/SetUp/mcq-exam/mcq-exam.component';


export const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'Register/:langKey', component: RegisterComponent },
  { path: 'MCQAdmin', component: AddMSQComponent },
  { path: 'MakeMCQ', component: ViewMCQComponent },
  { path: 'CourecView', component: CourcesViewComponent },
  { path: ':langKey/McqList', component: McqOnlineListComponent },
  { path: ':langKey/McqExam/:ExamId', component: McqExamComponent },
  { path: "DoResetPassword/:Token/:Email", component: DoResetPasswordComponent },
  { path: ":langKey/ContactUs", component: ContactusComponent },
  { path: ":langKey/Calendar", component: CalenderComponent },
  { path: ':langKey/media/:Id', component: MediaComponent },
  { path: ':langKey/:Page', component: ListComponent },
  { path: ':langKey/search/:Value', component: ListComponent },
  { path: ':langKey/:Page/:Id', component: DetailsComponent },
  { path: ':langKey', component: LandingComponent },
  { path: ':Calender', component: CalenderViewComponent }

];

@NgModule({
 
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]
})
export class AppRoutingModule { }
