<app-top-nav-bar></app-top-nav-bar>

<section id="hero">
  <div class="hero-container mb-5" style="top: 15vh;" data-aos="zoom-in" data-aos-delay="100">
    <section class="mx-auto mb-5 container" style="background:#808080;">

      <section class="container">
        <form [formGroup]="ForgetForm">
          <div class="container">
            <div class="row ">
              <div class="col-sm-12 ">
                <div class="form-group">
                  <label class="col-form-label" for="inputDefault">password</label>
                  <input type="password" class="form-control"
                         [ngClass]="{'is-invalid':ForgetForm.get('password').invalid && ForgetForm.get('password').touched}" formControlName="password">
                </div>
                <div class="form-group">
                  <p class="m-0  text-danger" style="white-space: pre;" *ngIf="ForgetForm.get('password').errors?.pattern&& ForgetForm.get('password').touched">Password must contains upper case charter and lower case character and one digit and one symbol and minimum 8 charters </p>
                </div>
                <div class="form-group ">
                  <label class="col-form-label" for="inputDefault">Confirm password</label>
                  <input type="password" class="form-control"
                         [ngClass]="{'is-invalid':ForgetForm.get('confirmpassword').invalid && ForgetForm.get('confirmpassword').touched}" formControlName="confirmpassword">

                </div>

                <div class="form-group">
                  <p class="m-0 text-danger" *ngIf="ForgetForm.controls['confirmpassword'].hasError('NoPassswordMatch')">Password don't match</p>
                </div>
                <div class="form-group mt-5 col-12">
                  <button class="btn btn-success m-auto col-12" (click)="Reset()" #ResetBtn>Reset Passowrd / إستعادة كلمة المرور</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </section>


  </div>


</section><!-- End Hero Section -->


<p-dialog [(visible)]="ThankYouModel" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}"
          [draggable]="false" [resizable]="false" [maximizable]="true">
  <form [formGroup]="ForgetForm">
    <div class="container">
      <div class="row ">
        <div class="col-sm-12 ">
          <p [innerHtml]="CommonService.CurrentLang.ThankYouPasswordReset"></p>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<p-toast></p-toast>
