import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Calendar } from '../../Models/Calander';
import { HomeService } from '../../Services/Home/home.service';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css']
})
export class CalenderComponent implements OnInit {

  constructor(private HomeService: HomeService, private route: ActivatedRoute) { }
  langKey = this.route.snapshot.paramMap.get("langKey") ?? "en";
  Calendar: Calendar[] = [];
  ngOnInit(): void {
    this.HomeService.GetAllCalendar(this.langKey).subscribe(res => {
      this.Calendar = res.list;
    })
  }

}
