import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ActionFilter } from '../../Models/Common/ActionFilter';
import { ElmResult } from '../../Models/Common/ElmResult';
import { ListReult } from '../../Models/Common/ListResults';
import { MediaWithPhotos, PhotoGaleryPhotos } from '../../Models/Media/PhotoGaleryPhotos';
import { PhotoGaleryHeadNames } from '../../Models/Media/PhotoGalleryNames';

@Injectable({
  providedIn: 'root'
})
export class MediaService {


  Url: string;
  constructor(protected _http: HttpClient) {
    this.Url = `${environment.baseUrl}${`PhotoGalery/`}`
  }


 
 
}
