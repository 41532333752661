export class Cources {
  courceCode: string;
  courceType: string = "0"; // 0 is for Work Shop , 1 for conference , 2 for seminars
  courceTitel: string = "";
  courceDate: string = "";
  courceGroup: string = "";
  courceImgLink: string = "";
  courceShortDesc: string = "";
  courceDesc: number = 53;
  courceMaxCount: number = 0;
  courceCurrentCount: number = 0;
  courcePreReq: string = "";
}
