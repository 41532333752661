import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../Services/Common/common.service';
import { ContactUsService } from '../../Services/ContactUs/contact-us.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  constructor(private ContactUsService: ContactUsService, private CommonService: CommonService) { }
  @ViewChild("submitBtn") SubmitBtn;
  ConatactUsForm: FormGroup;

  ngOnInit(): void {
    this.ConatactUsForm = new FormGroup({
      name: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$') ]),
      subject: new FormControl("", Validators.required),
      message: new FormControl("", Validators.required)
    })
  }
  ShowContactUs = true;
  ShowError=false;
  ErrorMessage="";
  Submit() {
    debugger;
    this.ConatactUsForm.markAllAsTouched();
    if (this.ConatactUsForm.valid) {
      this.ShowError = false;
      this.SubmitBtn.nativeElement.disabled = true;
      this.ContactUsService.PostContactUs(this.ConatactUsForm.value).subscribe(res => {
        this.SubmitBtn.nativeElement.disabled = false;
        if (res.success) {
          this.ShowContactUs = false;
        } else {
          this.ShowError = true;
          this.ErrorMessage = this.CommonService.CurrentLang.ContactUsError;
        }
      })
    }
  }
}
