<app-top-nav-bar></app-top-nav-bar>

<div class="content">

  

  <section id="hero" class="m-auto">
    <div style="top: 15vh;" class="hero-container" data-aos="zoom-in" data-aos-delay="100">
      <section class="col-12 row MyItemCtr" *ngIf="List.length>1">
        <section id="portfolio" class="portfolio" style="background-color:transparent;">
          <div class="container-fluid" data-aos="fade-up">
            <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
              <div *ngFor="let elm of List" [routerLink]="elm.link" class="col-lg-3 col-md-3 col-sm-6 portfolio-item MyItem">
                <img style="width:100%;height:234px;" src="{{elm.photoPath}}" class="img-fluid member" alt="">
                <div class="portfolio-info" style="height:100%;">
                  <h4>{{elm.title}}</h4>
                  <p [innerHTML]="elm.shortDesc"></p>
                  <a [routerLink]="elm.link" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="{{elm.title}}"><i class="bx bx-plus"></i></a>
                  <a [routerLink]="elm.link" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="p-paginator-bottom p-paginator p-component ng-star-inserted m-auto text-center">

            <span class="p-paginator-current ng-star-inserted">

              {{First}} -> {{Last}} : {{Total}}

            </span>
            <button (click)="FirstPage()" class="p-paginator-first p-paginator-element p-link p-ripple ng-star-inserted" pripple="" type="button">
              <span class="p-paginator-icon pi pi-angle-double-left"></span>
              <span class="p-ink"></span>
            </button>
            <button class="p-paginator-prev p-paginator-element p-link p-ripple" pripple="" type="button" (click)="Prev()">
              <span class="p-paginator-icon pi pi-angle-left"></span><span class="p-ink"></span>
            </button>
            <span class="p-paginator-pages ng-star-inserted">
              <button class="text-center btn"
                      pripple="" type="button" *ngFor="let element of Pages"
                      (click)="ChangePage(element)">
                {{element+1}}<span class="p-ink"></span>
              </button>
            </span>
            <button class="p-paginator-next p-paginator-element p-link p-ripple" pripple="" type="button" (click)="Next()">
              <span class="p-paginator-icon pi pi-angle-right"></span>
              <span class="p-ink"></span>
            </button>
            <button (click)="LastPage()" class="p-paginator-last p-paginator-element p-link p-ripple ng-star-inserted" pripple="" type="button">
              <span class="p-paginator-icon pi pi-angle-double-right"></span><span class="p-ink"></span>
            </button>

            <select [(ngModel)]="Rows" (change)="GetList()" style="width:80px;" class="form-control d-inline-block">
              <option value="1" >1</option>
              <option value="2" >2</option>
              <option value="3" >3</option>
              <option value="4" >4</option>
              <option value="5" selected>5</option>
              <option value="10" >10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

        </section><!-- End Portfolio Section -->
        <!--<section class="portfolio">
    <div class="container" data-aos="fade-up">
      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
        <div *ngFor="let elm of List; let index = index" class="col-md-3 col-sm-6 MyItem">
          <img style="width:380px;height:234px;" src="{{elm.photoPath}}" class="img-fluid" alt="">
          <div class="portfolio-info">
            <h4>{{elm.title}}</h4>
            <p [innerHTML]="elm.shortDesc"></p>
            <a [routerLink]="elm.link" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="{{elm.title}}"><i class="bx bx-plus"></i></a>
            <a [routerLink]="elm.link" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>-->
        <!-- End Portfolio Section -->
      </section>
 
      <section class="col-12 row MyItemCtr mb-4" *ngIf="List.length==1">
        <section *ngIf="DisplayCrausl" class="mx-auto mb-5 container">
          <ngb-carousel class="MyItem " #carousel [interval]="9000" [pauseOnHover]="true" [pauseOnFocus]="true" (slide)="onSlide($elm)">
            <ng-template ngbSlide *ngFor="let img of List[0]?.photosPath; index as i">
              <div class="picsum-img-wrapper">
                <img [src]="img">
              </div>
            </ng-template>
          </ngb-carousel>
        </section>

        <div style="background: #7a7a7b;" class="col-12 mb-4" *ngFor="let elm of List; let index = index">
          <div class="member">
            <h2 class="mt-4 text-center">{{elm.title}}</h2>
            <div class="Text text-center px-5" [innerHTML]="transform(elm.shortDesc)"></div>
          </div>
        </div>
      </section>
    </div>
  </section>
</div>
