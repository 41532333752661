
<div class="content">

  <section id="hero" class="m-auto">
    <div class="hero-container m-auto" data-aos="zoom-in" data-aos-delay="100" style="max-height:70vh;margin-top:30%;">
      <section style="top: 10vh;min-height:200px;margin-top:15vh;">
        <h1 style="color:#ffffff">MCQ Test</h1>

        <br />
        <hr />
        <div class="text-end mt-3 mb-3">
          <button class="btn btn-success" type="submit" (click)="showREsult()">Send</button>
        </div>
      </section>

      <section style="margin-bottom:10%;top: 10vh;min-height:700px;min-width:500px;overflow-y:auto;background-color: rgba(255,255,255,0.8);" id="Cnt;">
        <form>
          <div>
            <div *ngFor="let item of mcq; index as i;" class="card border-0 pb-2 shadow mcq mb-5">

              <div class="card-body">
                <div class="d-flex justify-content-between text-secondary" style="font-style:italic">
                  <label><i class="fas fa-comment-dots"></i> Question {{i+1}}</label>
                  <div>{{(item.multiAnswers == true) ? 'choose multi Answers' : 'choose one Answer'}}</div>
                </div>
                <h5 class="card-title mt-3 mb-3">{{item.question}}</h5>
                <ul class="list-group list-unstyled">
                  <li *ngFor="let answer of item.answers; index as ii;" class="p-0 list-group-item list-group-item-action">
                    <input [type]="(item.multiAnswers == true) ? 'checkbox' : 'radio'" value="{{ii}}" class="CheckOrRadio d-none" (change)="setUserAnswer(i, ii, $event)" id="{{i}}{{ii}}" name="{{i}}">
                    <label for="{{i}}{{ii}}" class="d-block p-2" [ngClass]="{'active': result, 'correct': item.evaluation == 'success', 'inCorrect': item.evaluation == 'no success'}"> <span class="border round-sm text-secondary">{{AnswerIcon[ii]}}</span> {{answer.answer}}</label>
                  </li>
                </ul>
              </div>
            </div>

            
          </div>
        </form>
      </section>
    </div>
  </section><!-- End Hero Section -->
</div>



<app-top-nav-bar></app-top-nav-bar>
