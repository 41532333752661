<app-top-nav-bar></app-top-nav-bar>

<section id="hero">
  <div class="hero-container mb-5" style="top: 15vh;"  data-aos="zoom-in" data-aos-delay="100" >
    <section class="mx-auto mb-5 container">
      <ngb-carousel class="MyItem " #carousel [interval]="9000" [pauseOnHover]="true" [pauseOnFocus]="true" (slide)="onSlide($event)">
        <ng-template ngbSlide *ngFor="let img of Elm?.photos; index as i">
          <!--<div class="carousel-caption">
          <h3>My slide {{i + 1}} title</h3>
        </div>-->
          <div class="picsum-img-wrapper">
            <img [src]="img">
          </div>
        </ng-template>
      </ngb-carousel>
    </section>

    <section class="container">

      <section class="col-12">
        <h4 style="color:#ffffff;" class="" *ngIf="ShowTitle">{{Elm?.title}}</h4>
        <hr />
      </section>
      <section class="col-12" style="color:#ffffff;overflow-y:scroll;height:300px;">
        <div style="padding-bottom:60px;" *ngIf="ShowBody" [innerHtml]="Elm?.body"></div>
      </section>
    </section>

  </div>

 
</section><!-- End Hero Section -->



