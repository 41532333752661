import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SaveResult } from '../../Models/Common/SaveResult';
import { LoginModule, LoginResponse } from '../../Models/Users/login.module';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  Url: string;
  constructor(protected _http: HttpClient) {
    this.Url = `${environment.baseUrl}${`Accounts/`}`
  }

  Login(login: LoginModule) {
    return this._http.post<LoginResponse>(`${this.Url}Login`, login);
  }
  ResetPassword(Email: string) {
    return this._http.post<SaveResult>(`${this.Url}ResetPassword/${Email}/${true}`, null);
  }
  DoResetPassword(Paramter) {
    return this._http.post<SaveResult>(`${this.Url}DoResetPassword`, Paramter);
  }
  ChangePassword(Paramter) {
    return this._http.post<SaveResult>(`${this.Url}ChangePassword`, Paramter);
  }
  IsLoggedIn(): boolean {
    if (sessionStorage.getItem('IsLogedIn') == 'true') {
      return true;
    } else {
      return false;
    }

  }
}
