import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class BasicInterceptor implements HttpInterceptor {


  constructor(private Router: Router) { }
  intercept(httpRequest: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    httpRequest = httpRequest.clone({
      setHeaders: {
        // 'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem("token") == null ? "" : sessionStorage.getItem("token").toString(),
        'url': this.Router.url
      },

    })
    return next.handle(httpRequest).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {

          if (err.status === 401) {
            this.Router.navigate(["/"]);
          }
          // return the error back to the caller
          return throwError(err);
        }
      }),
      finalize(() => {
        // any cleanup or final activities.
      })
    )
  }

}
