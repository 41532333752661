<app-top-nav-bar></app-top-nav-bar>

<div class="content">


  <section id="hero" class="m-auto">
    <div class="hero-container m-auto" data-aos="zoom-in" data-aos-delay="100" style="max-height:70vh;max-width:80vw;margin-top:30%;">
      <section style="top: 10vh;min-height:200px;margin-top:15vh;">
        <h1 style="color:#ffffff">STEP {{current_step+1}} - {{selectedStep.StepTitel}}</h1>
        <br />
        <h5 style="color:#808080">{{selectedStep.StepDesc}}</h5>

        <div class="btn-group">
          <div class="btn-group mr-2" role="group" aria-label="Third group" *ngIf="current_step>0">
            <button type="button" class="btn btn-secondary float-center" data-dismiss="modal" (click)="goBack(stepper)">Back</button>
          </div>
          <div class="btn-group mr-2" role="group" aria-label="Third group" *ngIf="current_step<totalStepsCount-1">
            <button type="button" class="btn btn-primary float-center" data-dismiss="modal" (click)="goForward(stepper)">Next </button>
          </div>

        </div>

      </section>
      <section style="top: 10vh;min-height:500px;overflow-y:auto;" id="Cnt">

        <form [formGroup]="registerForm">

          <mat-horizontal-stepper #stepper>
            <mat-step>

              <!--<ng-template matStepLabel>{{AllSteps[0].StepTitel}}</ng-template>-->

              <div class="row">

                <div class="row">
                  <div class="form-group col-md-4">
                    <label for="validationCustom01" class="form-label">First name</label>
                    <input type="text" formControlName="fname" class="form-control"
                           [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" (focusout)="focusOutFunction()">
                    <!--<div class="invalid-feedback">Please enter a name</div>-->
                    <!--<div class="valid-feedback">Looks good!</div>-->

                    <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                      <div *ngIf="f.fname.errors.required">First Name Is Required</div>
                      <div *ngIf="f.fname.errors.TextHasNumber">Found Numbers</div>
                    </div>

                  </div>
                  <div class="form-group col-md-4">
                    <label for="validationCustom02" class="form-label">Middle name</label>
                    <input type="text" formControlName="midname" class="form-control"
                           [ngClass]="{ 'is-invalid': submitted && f.midname.errors }" (focusout)="focusOutFunction()">
                    <!--<div class="valid-feedback"> Looks good! </div>-->
                    <!--<div class="invalid-feedback">Please enter a name</div>-->

                    <div *ngIf="submitted && f.midname.errors" class="invalid-feedback">
                      <div *ngIf="f.midname.errors.required">Middel Name is required</div>
                      <div *ngIf="f.midname.errors.TextHasNumber">Found Numbers</div>
                    </div>

                  </div>
                  <div class="form-group col-md-4">
                    <label for="validationCustomUsername" class="form-label">Last Name</label>
                    <input type="text" formControlName="lastName" class="form-control"
                           [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" (focusout)="focusOutFunction()">
                    <!--<div class="valid-feedback"> Looks good! </div>-->
                    <!--<div class="invalid-feedback">Please enter a name</div>-->

                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                      <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                      <div *ngIf="f.lastName.errors.TextHasNumber">Found Numbers</div>
                    </div>

                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="validationCustom04" class="form-label">Gender</label>
                    <select class="form-select" id="validationCustom04" formControlName="genderid"
                            [ngClass]="{ 'is-invalid': submitted && f.genderid.errors }">
                      <option selected disabled value="">Choose...</option>
                      <option [value]="1">Male</option>
                      <option [value]="2">Female</option>
                    </select>
                    <!--<div class="invalid-feedback"> Please select a valid Gender </div>-->
                    <div *ngIf="submitted && f.genderid.errors" class="invalid-feedback">
                      <div *ngIf="f.genderid.errors.required">Gender is required</div>
                    </div>

                  </div>
                  <div class="col-md-4">
                    <label for="validationCustom04" class="form-label">Date Of Birth</label>
                    <input type="date" class="form-control" id="validationCustom01" formControlName="birthdate"
                           [ngClass]="{ 'is-invalid': submitted && f.birthdate.errors }" [max]="maxDate" />
                    <!--<div class="invalid-feedback">Please select a valid Date</div>-->
                    <div *ngIf="submitted && f.birthdate.errors" class="invalid-feedback">
                      <div *ngIf="f.birthdate.errors.length>0">Date Of Birth is required</div>
                      <div *ngIf="f.birthdate.errors.AgeIsGood">Age Must > 20 Years Old</div>
                    </div>

                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="validationCustom03" class="form-label">Country</label>
                    <select class="form-control" data-live-search="true" formControlName="counteryid"
                            [ngClass]="{ 'is-invalid': submitted && f.counteryid.errors }" >
                      <option value="" disabled selected>{{CommonService.CurrentLang.SelcCountry}}</option>
                      <option *ngFor="let employee of ListOfConunty  ; let i=index;"
                              [value]="employee.counteryId">
                        {{employee.counteryName}}
                      </option>

                    </select>

                    <div *ngIf="submitted && f.counteryid.errors" class="invalid-feedback">
                      <div *ngIf="f.counteryid.errors.required">Country is required</div>
                    </div>

                  </div>

                  <div class="col-md-4">
                    <label for="validationCustom05" class="form-label">Zip</label>
                    <input type="text" class="form-control" id="validationCustom05" formControlName="zipcode"
                           [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }">

                    <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                      <div *ngIf="f.zipcode.errors.required">Zip required</div>
                    </div>

                  </div>
                </div>

                <!--<div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"  id="invalidCheck" formControlName="lastName"
                           [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" required>
                    <label class="form-check-label" for="invalidCheck">
                      Agree to terms and conditions
                    </label>
                    <div class="invalid-feedback">
                      You must agree before submitting.
                    </div>
                  </div>
                </div>-->

              </div>


            </mat-step>
            <mat-step>
              <div class="row  ">
                <div class="row">
                  <div class="col-md-3">
                    <label for="validationCustomUsername" class="form-label">Official E-mail / Login Email</label>
                    <div class="input-group has-validation">
                      <span class="input-group-text" id="inputGroupPrepend">@</span>
                      <input type="text" class="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend"
                             formControlName="officeemail"
                             [ngClass]="{ 'is-invalid': submitted && f.officeemail.errors }">

                      <div *ngIf="submitted && f.officeemail.errors" class="invalid-feedback">
                        <div *ngIf="f.officeemail.errors.required">Email Is Required</div>
                        <div *ngIf="f.officeemail.errors.email">Email Is not Corrrect Formate</div>
                      </div>

                    </div>
                  </div>
                  <div class="col-md-3">
                    <label for="validationCustomUsername" class="form-label">Personal E-mail</label>
                    <div class="input-group has-validation">
                      <span class="input-group-text" id="inputGroupPrepend">@</span>
                      <input type="text" class="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend"
                             formControlName="personalemail"
                             [ngClass]="{ 'is-invalid': submitted && f.personalemail.errors }">

                      <div *ngIf="submitted && f.personalemail.errors" class="invalid-feedback">
                        <div *ngIf="f.personalemail.errors.required"> Personal Email Is Required</div>
                        <div *ngIf="f.personalemail.errors.email">Email Is not Corrrect Formate</div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <label for="validationCustom03" class="form-label">Office Phone No</label>
                    <input type="text" class="form-control" id="validationCustom03" formControlName="officephone"
                           [ngClass]="{ 'is-invalid': submitted && f.officephone.errors }">

                    <div *ngIf="submitted && f.officephone.errors" class="invalid-feedback">
                      <div *ngIf="f.officephone.errors.required">Office Phone Required</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label for="validationCustom03" class="form-label">Home Phone No</label>
                    <input type="text" class="form-control" id="validationCustom03" formControlName="homephone"
                           [ngClass]="{ 'is-invalid': submitted && f.homephone.errors }">

                    <div *ngIf="submitted && f.homephone.errors" class="invalid-feedback">
                      <div *ngIf="f.homephone.errors.required">Home Phone required</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <label for="validationCustom03" class="form-label">Mobile Phone No</label>
                    <input type="text" class="form-control" id="validationCustom03" formControlName="mobilephone"
                           [ngClass]="{ 'is-invalid': submitted && f.mobilephone.errors }">
                    <div *ngIf="submitted && f.mobilephone.errors" class="invalid-feedback">
                      <div *ngIf="f.mobilephone.errors.required">Mobile Phone required</div>
                    </div>
                  </div>


                </div>



              </div>

            </mat-step>
            <mat-step>
              <!--<ng-template matStepLabel>PASSPORT AND TRAVEL INFORMATION </ng-template>-->
              <div class="row  ">
                <div class="row">
                  <div class="col-md-3">
                    <label for="validationCustomUsername" class="form-label">Passport Number</label>
                    <input type="text" class="form-control" id="validationCustom05" formControlName="passportnumber"
                           [ngClass]="{ 'is-invalid': submitted && f.passportnumber.errors }">
                    <div *ngIf="submitted && f.passportnumber.errors" class="invalid-feedback">
                      <div *ngIf="f.passportnumber.errors.required">Passport Number required</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label for="validationCustomUsername" class="form-label">Issued By</label>
                    <input type="text" class="form-control" id="validationCustom05" formControlName="issuedby"
                           [ngClass]="{ 'is-invalid': submitted && f.issuedby.errors }" required>
                    <div *ngIf="submitted && f.issuedby.errors" class="invalid-feedback">
                      <div *ngIf="f.issuedby.errors.required">Issued By required</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="validationCustomUsername" class="form-label">Name as it appears on passport</label>
                    <input type="text" class="form-control" id="validationCustom05" formControlName="passportname"
                           [ngClass]="{ 'is-invalid': submitted && f.passportname.errors }" required>
                    <div *ngIf="submitted && f.passportname.errors" class="invalid-feedback">
                      <div *ngIf="f.passportname.errors.required">Name as it appears on passport required</div>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-md-3">
                    <label for="validationCustom03" class="form-label">Issued Date</label>
                    <input type="date" class="form-control" id="validationCustom05" formControlName="issuedate"
                           [ngClass]="{ 'is-invalid': submitted && f.issuedate.errors }" required>
                    <div *ngIf="submitted && f.issuedate.errors" class="invalid-feedback">
                      <div *ngIf="f.issuedate.errors.required">Issued Date required</div>
                    </div>

                  </div>
                  <div class="col-md-3">
                    <label for="validationCustom03" class="form-label">Expiration date</label>
                    <input type="date" class="form-control" id="validationCustom05" formControlName="expiredate"
                           [ngClass]="{ 'is-invalid': submitted && f.expiredate.errors }" required>
                    <div *ngIf="submitted && f.expiredate.errors" class="invalid-feedback">
                      <div *ngIf="f.expiredate.errors.required">Expiration date required</div>
                    </div>
                  </div>
                </div>

                <!--<div class="row">
                  <div class="col-md-3">
                    <label for="validationCustom03" class="form-label">Departure International Airport</label>
                    <input type="text" class="form-control" id="validationCustom03" formControlName="depatureairport"
                           [ngClass]="{ 'is-invalid': submitted && f.depatureairport.errors }" required>
                    <div *ngIf="submitted && f.depatureairport.errors" class="invalid-feedback">
                      <div *ngIf="f.depatureairport.errors.required">Departure International Airport required</div>
                    </div>
                  </div>


                </div>-->



              </div>

            </mat-step>
            <mat-step>
              <!--<ng-template matStepLabel>PASSPORT AND TRAVEL INFORMATION </ng-template>-->
              <div class="row  ">
                <div class="row">
                  <div class="col-md-3">
                    <label for="validationCustomUsername" class="form-label">Emergency contact’s name</label>
                    <input type="text" class="form-control" id="validationCustom05" formControlName="emergencycontct"
                           [ngClass]="{ 'is-invalid': submitted && f.emergencycontct.errors }">
                    <div *ngIf="submitted && f.emergencycontct.errors" class="invalid-feedback">
                      <div *ngIf="f.emergencycontct.errors.required">Emergency contact’s name required</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label for="validationCustomUsername" class="form-label">Relationship</label>
                    <input type="text" class="form-control" id="validationCustom05" formControlName="emergencyreklation"
                           [ngClass]="{ 'is-invalid': submitted && f.emergencyreklation.errors }">
                    <div *ngIf="submitted && f.emergencyreklation.errors" class="invalid-feedback">
                      <div *ngIf="f.emergencyreklation.errors.required">Relationship required</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="validationCustomUsername" class="form-label">Telephone</label>
                    <input type="text" class="form-control" id="validationCustom05" formControlName="emergencytel"
                           [ngClass]="{ 'is-invalid': submitted && f.emergencytel.errors }">
                    <div *ngIf="submitted && f.emergencytel.errors" class="invalid-feedback">
                      <div *ngIf="f.emergencytel.errors.required">Telephone required</div>
                    </div>
                  </div>

                </div>

              </div>

            </mat-step>
            <mat-step>
              <div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1" style="text-align:left">CV</label>
                      <input type="file" name="file"  accept=".doc,.docx,.pdf" (change)="AddFile($event,0,['pdf','doc','docx'])" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1">Passport</label>
                      <input type="file" name="file" accept=".doc,.docx,.pdf,.png, .jpeg, .jpg" (change)="AddFile($event,1,['pdf','doc','docx','png','jpg','jpeg'])" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1">Personal Photo</label>
                      <input type="file" name="file"  accept=".png, .jpeg, .jpg" (change)="AddFile($event,2,['png','jpg','jpeg'])" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label for="validationCustom03" class="form-label">Skills</label>
                    <textarea class="form-control" id="validationCustom03" formControlName="skills" rows="5"></textarea>
                  </div>
                  <div class="col-md-6">
                    <label for="validationCustom03" class="form-label">Experience</label>
                    <textarea type="text" class="form-control" id="validationCustom03" formControlName="experience" rows="5"></textarea>
                  </div>
                </div>

              </div>
            </mat-step>
            <mat-step>
              <div>
                <div class="form-group" >
                  <label class="col-form-label" for="inputDefault">password</label>
                  <input type="password" class="form-control"
                         [ngClass]="{'is-invalid':f.password.invalid && f.password.touched}" formControlName="password">
                  <span class="m-0  text-danger" *ngIf="f.password.errors?.pattern&& f.password.touched">Password must contains upper case charter and lower case character and one digit and one symbol and minimum 8 charters </span>

                </div>
                <div class="form-group " >
                  <label class="col-form-label" for="inputDefault">Confirm password</label>
                  <input type="password" class="form-control"
                         [ngClass]="{'is-invalid':f.confirmpassword.invalid && f.confirmpassword.touched}" formControlName="confirmpassword">

                  <span class="m-0 text-danger" *ngIf="f.confirmpassword.hasError('NoPassswordMatch')">Password don't match</span>
                </div>
                <div style="align-content:center">
                  <button class="btn btn-warning btn-lg btn-block" (click)="onSubmit()" type="submit" [disabled]="ISLoading">{{SaveTxt}}</button>
                </div>
                <div class="loader" *ngIf="ISLoading===true"></div>
              </div>
            </mat-step>


          </mat-horizontal-stepper>

        </form>
      </section>
    </div>
  </section><!-- End Hero Section -->








</div>

<p-toast></p-toast>
