import { Component, OnInit } from '@angular/core';
import { Cources } from '../../Models/Cources.model';

@Component({
  selector: 'app-cources-view',
  templateUrl: './cources-view.component.html',
  styleUrls: ['./cources-view.component.css']
})
export class CourcesViewComponent implements OnInit {

  constructor() { }


  ListCources: Cources[] = [];
  ngOnInit() {

    let OneNew: Cources = new Cources();
    OneNew.courceDate = "0";
    OneNew.courceGroup = "0";
    OneNew.courceTitel = "INTEGRATED PLANNING FOR CLIMATE";
    OneNew.courceShortDesc = "virtual high-level panel titled virtual ";
    OneNew.courceCurrentCount = 30;
    OneNew.courceMaxCount = 50;
    OneNew.courceImgLink = "https://www.unitar.org/sites/default/files/styles/max_xs_landscape_720px450px_/public/media/image/free-open1.jpg?itok=JtzvgUMM";
    OneNew.courceDate = "29-Sep-2021 to 30-Dec-2021";
    this.ListCources.push(OneNew);

    OneNew = new Cources();
    OneNew.courceDate = "0";
    OneNew.courceGroup = "0";
    OneNew.courceTitel = "HUMAN RIGHTS AND THE ENVIRONMENT";
    OneNew.courceShortDesc = "This self-paced course, jointly delivered by ";
    OneNew.courceCurrentCount = 30;
    OneNew.courceMaxCount = 50;
    OneNew.courceImgLink = "https://www.unitar.org/sites/default/files/styles/max_xs_landscape_720px450px_/public/media/image/free-open2.jpg?itok=S1sp59bV";
    OneNew.courceDate = "29-Sep-2021 to 30-Dec-2021";
    this.ListCources.push(OneNew);

    OneNew= new Cources();
    OneNew.courceDate = "0";
    OneNew.courceGroup = "0";
    OneNew.courceTitel = "HUMAN RIGHTS AND THE ENVIRONMENT";
    OneNew.courceShortDesc = "virtual high-level panel titled";
    OneNew.courceCurrentCount = 30;
    OneNew.courceMaxCount = 50;
    OneNew.courceDate = "29-Sep-2021 to 30-Dec-2021";
    OneNew.courceImgLink = "https://www.unitar.org/sites/default/files/styles/max_xs_landscape_720px450px_/public/media/image/free-open2.jpg?itok=S1sp59bV";
    this.ListCources.push(OneNew);

    OneNew = new Cources();
    OneNew.courceDate = "0";
    OneNew.courceGroup = "0";
    OneNew.courceTitel = "INTRODUCTION TO THE 2030 AGENDA";
    OneNew.courceShortDesc = "virtual high-level panel titled";
    OneNew.courceCurrentCount = 30;
    OneNew.courceMaxCount = 50;
    OneNew.courceDate = "29-Sep-2021 to 30-Dec-2021";
    OneNew.courceImgLink = "https://www.unitar.org/sites/default/files/styles/max_xs_landscape_720px450px_/public/media/image/free-open3.jpg?h=dbcaf4ac&itok=bXf44uOy";
    this.ListCources.push(OneNew);
    console.log("Cource");

    OneNew = new Cources();
    OneNew.courceDate = "0";
    OneNew.courceGroup = "0";
    OneNew.courceTitel = "INTRODUCTION TO THE 2030 AGENDA";
    OneNew.courceShortDesc = "virtual high-level panel titled";
    OneNew.courceCurrentCount = 30;
    OneNew.courceMaxCount = 50;
    OneNew.courceDate = "29-Sep-2021 to 30-Dec-2021";
    OneNew.courceImgLink = "https://www.unitar.org/sites/default/files/styles/max_xs_landscape_720px450px_/public/media/image/free-open3.jpg?h=dbcaf4ac&itok=bXf44uOy";
    this.ListCources.push(OneNew);
    console.log("Cource");

    OneNew = new Cources();
    OneNew.courceDate = "0";
    OneNew.courceGroup = "0";
    OneNew.courceTitel = "INTRODUCTION TO THE 2030 AGENDA";
    OneNew.courceShortDesc = "virtual high-level panel titled";
    OneNew.courceCurrentCount = 30;
    OneNew.courceMaxCount = 50;
    OneNew.courceDate = "29-Sep-2021 to 30-Dec-2021";
    OneNew.courceImgLink = "https://www.unitar.org/sites/default/files/styles/max_xs_landscape_720px450px_/public/media/image/free-open3.jpg?h=dbcaf4ac&itok=bXf44uOy";
    this.ListCources.push(OneNew);
    console.log("Cource");
    


  }

}
