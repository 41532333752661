import { CenterPartsEnum } from "../Home/CenterParts";

export class ActionFilter {
  pageNumber: number;
  getRows: number;
  filter?: string;
  langKey?: string;
  orderBy?: string;
  orderType?: ordertype = ordertype.asc;
  langId?: number;
}
export class CenterActionFilter extends ActionFilter{
  centerPartsEnum: CenterPartsEnum;
}

export enum ordertype {
  asc,
  descending
}
