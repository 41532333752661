
  <div>
    <section id="hero" class="m-auto">
      <div class="hero-container m-auto" data-aos="zoom-in" data-aos-delay="100" style="max-height:70vh;max-width:80vw;margin-top:30%;">
        <section style="top: 10vh;min-height:200px;margin-top:15vh;">
          <h1 style="color:#ffffff">Cources List</h1>
          <br />
          <div class="container">

            <div class="card-columns">
                  <div class="card" *ngFor="let cource of ListCources; let index = index">
                    <img src="{{cource.courceImgLink}}" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h5 class="card-title">{{cource.courceTitel}}</h5>
                      <p class="card-text"> {{cource.courceShortDesc}}</p>
                      <div class="mt-5">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="mt-3"> <span class="text1">{{cource.courceCurrentCount}} Applied <span class="text2">of {{cource.courceMaxCount}} capacity</span></span> </div>
                      </div>
                    </div>
                  </div>
            </div>


          


            </div>
         </section>
        </div>
        </section>
  </div>

      <app-top-nav-bar></app-top-nav-bar>
