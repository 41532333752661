import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SaveResult } from '../../Models/Common/SaveResult';
import { ContactUs } from '../../Models/ContactUs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  Url: string;
  constructor(protected _http: HttpClient) {
    this.Url = `${environment.baseUrl}${`Content/`}`
  }
  PostContactUs(ContactUs: ContactUs): Observable<SaveResult> {
    return this._http.post<SaveResult>(`${this.Url}SaveContactUs`, ContactUs);
  }
}
