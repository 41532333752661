import { Component, OnInit } from '@angular/core';
import { Question } from '../../Models/Question.model';
import { Answer } from '../../Models/Answer.model';
import { MatCheckbox } from '@angular/material';
import { APICallsService } from '../../Services/apicalls.service';

@Component({
  selector: 'app-add-msq',
  templateUrl: './add-msq.component.html',
  styleUrls: ['./add-msq.component.css']
})
export class AddMSQComponent implements OnInit {

  constructor(public API: APICallsService) { }

  Sample: Question = new Question();

  ngOnInit() {
    let sing: Answer = new Answer();
    sing.Answer = "Answer 1";
    this.Sample.Answers.push(sing);

    sing = new Answer();
    sing.Answer = "Answer 2";
    this.Sample.Answers.push(sing);

    sing = new Answer();
    sing.Answer = "Answer 3";
    this.Sample.Answers.push(sing);

    sing = new Answer();
    sing.Answer = "Answer 4";
    this.Sample.Answers.push(sing);

  }

  checkboxChangeSelect(checkbox: MatCheckbox): void {
    //console.log('check');
    //if (this.Sample.Answers[0].right === true) {
    //  this.Sample.Answers[0].right = false;
    //}
    //else {
    //  this.Sample.Answers[0].right = true;
    //}
  }

  checkboxChangeSelect1(checkbox: MatCheckbox): void {
    console.log('check');
    if (this.Sample.Answers[0].right === true) {
      this.Sample.Answers[0].right = false;
    }
    else {
      this.Sample.Answers[0].right = true;
    }
  }

  checkboxChangeSelect2(checkbox: MatCheckbox): void {
    console.log('check');
    if (this.Sample.Answers[1].right === true) {
      this.Sample.Answers[1].right = false;
    }
    else {
      this.Sample.Answers[1].right = true;
    }
  }

  checkboxChangeSelect3(checkbox: MatCheckbox): void {
    console.log('check');
    if (this.Sample.Answers[2].right === true) {
      this.Sample.Answers[2].right = false;
    }
    else {
      this.Sample.Answers[2].right = true;
    }
  }

  checkboxChangeSelect4(checkbox: MatCheckbox): void {
    console.log('check');
    if (this.Sample.Answers[3].right === true) {
      this.Sample.Answers[3].right = false;
    }
    else {
      this.Sample.Answers[3].right = true;
    }
  }

  SaveData() {

    console.log(this.Sample);
    this.API.SaveQuestion(this.Sample).subscribe(
      Data => {
        console.log('Post');
        alert(Data);
        

      },
      err => {
        alert(err.error);
      });
  }

}
