import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ElmResult } from '../../Models/Common/ElmResult';
import { SaveResult } from '../../Models/Common/SaveResult';
import { Exam } from '../../Models/MCQ/Student/Exam';
import { StudentAnswerCnt } from '../../Models/MCQ/Student/StudentAnswer';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  Url: string;
  constructor(protected http: HttpClient) {
    this.Url = `${environment.baseUrl}Questions/`
  }

  GetExamSetting(ExamId: number): Observable<ElmResult<Exam>> {
    return this.http.get<ElmResult<Exam>>(`${this.Url}GetExamSetting/${ExamId}`);
  }
  SubmitAnswers(StudentAnswers: StudentAnswerCnt): Observable<SaveResult> {
    return this.http.post<SaveResult>(`${this.Url}SubmitQuestionAnswer/`, StudentAnswers);
  }
}
