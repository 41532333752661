<app-top-nav-bar></app-top-nav-bar>

<section id="hero">
  <div class="hero-container mb-5" style="top: 15vh;" data-aos="zoom-in" data-aos-delay="100">
    <section class="mx-auto mb-5 container">
      <p-table [lazy]="true"
               (onLazyLoad)="loadResult($event)"
               [value]="Subject" [rowsPerPageOptions]="[10, 25, 50]" [rows]="50"
               [rowHover]="true" responsiveLayout="scroll"
               [loading]="loading" [paginator]="true"
               [totalRecords]="Total"
               [showCurrentPageReport]="true"
               styleClass="excel-print p-datatable-sm"
               class="p-datatable-responsive-demo M">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="SubjectMaster.SubjectMasterDesc">
              {{CommonService.CurrentLang.SubjectName}}
            </th>
            <th>
              {{CommonService.CurrentLang.Exam}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-Course>
          <tr>
            <td>{{Course.name}}</td>
            <td>
              <button class="btn" (click)="GoToExam(Course.id)" style="color:#000000;">
                <i class="fa fa-user text-info"></i> {{CommonService.CurrentLang.Exam}}
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </section>
  </div>


</section><!-- End Hero Section -->
