import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Calendar } from '../../Models/Calander';
import { ActionFilter, CenterActionFilter } from '../../Models/Common/ActionFilter';
import { ElmResult } from '../../Models/Common/ElmResult';
import { ListReult } from '../../Models/Common/ListResults';
import { NameId } from '../../Models/Common/NameId';
import { HomeModel } from '../../Models/Home/Home';
import { HomeDetails } from '../../Models/Home/HomeDetails';
import { MediaWithPhotos } from '../../Models/Media/PhotoGaleryPhotos';
import { PhotoGaleryHeadNames } from '../../Models/Media/PhotoGalleryNames';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  Url: string;
  constructor(protected _http: HttpClient) {
    this.Url = `${environment.baseUrl}${`Home/`}`
  }
  GetNewsHomePage(filter: ActionFilter): Observable<ListReult<HomeModel>> {
    return this._http.post<ListReult<HomeModel>>(`${this.Url}GetNewsHomePage`, filter);
  }
  GetEventsHomePage(filter: ActionFilter): Observable<ListReult<HomeModel>> {
    return this._http.post<ListReult<HomeModel>>(`${this.Url}GetEventsHomePage`, filter);
  }
  
  GetCenterPart(filter: CenterActionFilter): Observable<ListReult<HomeModel>> {
    return this._http.post<ListReult<HomeModel>>(`${this.Url}GetCenterPart`, filter);
  }
  

  GetCenterPartSimple(LangId: string): Observable<ListReult<any>> {
    return this._http.get<ListReult<any>>(`${environment.baseUrl}${`Content/GetContent?LangId=` + LangId}`);
  }

 


  GetNewsDetails(langKey: string, link: string): Observable<ElmResult<HomeDetails>> {
    return this._http.get<ElmResult<HomeDetails>>(`${this.Url}GetNewsDetails/${langKey}/${link}`);
  }
  GetCenterPartDetails(langKey: string, link: string): Observable<ElmResult<HomeDetails>> {
    return this._http.get<ElmResult<HomeDetails>>(`${this.Url}GetCenterPartDetails/${langKey}/${link}`);
  }
  GetEventsDetails(langKey: string, link: string): Observable<ElmResult<HomeDetails>> {
    return this._http.get<ElmResult<HomeDetails>>(`${this.Url}GetEventsDetails/${langKey}/${link}`);
    }
  GetPhotoGaleryHeadNames(Actionfitler: ActionFilter) {
    return this._http.post<ListReult<PhotoGaleryHeadNames>>(`${this.Url}GetPhotoGaleryHeadNamesHome`, Actionfitler);
    }
  GetGaleryPhotos(LangKey, Id): Observable<ElmResult<MediaWithPhotos>> {
    return this._http.get<ElmResult<MediaWithPhotos>>(`${this.Url}GetGaleryPhotos/${LangKey}/${Id}`)
  }
  Search(LangKey,Value): Observable<ListReult<string>> {
    return this._http.get<ListReult<string>>(`${this.Url}Search/${LangKey}/${Value}`)
  }
  //GetAllCalendar(LangKey): Observable<ListReult<Calendar>> {
  //  return this._http.get<ListReult<Calendar>>(`${this.Url}GetAllCalendar/${LangKey}`)
  //}

  GetAllCalendar(LangKey: string): Observable<ListReult<Calendar>>{
    let Code = -1;
    if (LangKey == "en")
      Code = 1;
    if (LangKey == "ar")
      Code = 2;
    if (LangKey == "fn")
      Code = 3;
    return this._http.get<ListReult<any>>(environment.baseUrl + "Calender/GetCalender?langid=" + Code);
  }
  GetSubject(Filter: ActionFilter): Observable<ListReult<NameId>> {
    return this._http.post<ListReult<NameId>>(`${this.Url}GetStudentCourses`, Filter);
  }
}
