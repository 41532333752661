import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//import {
//  TranslateService,
//  LangChangeEvent
//} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MITTRAIN';

  textDir: string = 'ltr';


  constructor( private route: ActivatedRoute) {
    //this is to determine the text direction depending on the selected language
    //this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //  if (event.lang == 'ar') {
    //    this.textDir = 'rtl';
    //  } else {
    //    this.textDir = 'ltr';
    //  }
    //});
  }
}
