import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ElmResult } from '../../Models/Common/ElmResult';
import { HomeDetails } from '../../Models/Home/HomeDetails';
import { CommonService } from '../../Services/Common/common.service';
import { HomeService } from '../../Services/Home/home.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import '@angular/localize/init';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  constructor(private HomeService: HomeService,
    private route: ActivatedRoute,
    private CommonService: CommonService) { }


  langKey = this.route.snapshot.paramMap.get("langKey") ?? "en";
  Page = this.route.snapshot.paramMap.get("Page");
  Id = this.route.snapshot.paramMap.get("Id");
  Elm: HomeDetails;
  Done = false;
  ShowTitle = true;
  ShowBody = true;
  ngOnInit(): void {
    this.CommonService.GetDetails(this);
  }

 //#region  Carosal

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
 //#endregion  Carosal
}
