import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    try {
      const password: string = control.get('password').value; // get password from our password form control
      const confirmPassword: string = control.get('confirmpassword').value; // get password from our confirmPassword form control
      // compare is the password math
      if (password !== confirmPassword) {
        // if they don't match, set an error in our confirmPassword form control
        control.get('confirmpassword').setErrors({ NoPassswordMatch: true });
      } else {
        control.get('confirmpassword').setErrors(null);
        control.get('confirmpassword').setValidators(Validators.required);
      }
    } catch (err) {

    }
   
  }
  static cannotContainSpace(control: AbstractControl) {
    try {
      if (control.get('officeemail').value == '') {
        control.get('officeemail').setErrors({ requierd: true });
      } else {
        if (control.get('officeemail').value.indexOf(' ') >= 0) {
          control.get('officeemail').setErrors({ WhiteSpaces: true });
        } else {
          control.get('officeemail').setErrors(null);
        }
      }
      

    } catch (err) {

    }
  }
  static EmailMatchValidator(control: AbstractControl) {
    const email: string = control.get('email').value; // get password from our password form control
    const confirmemail: string = control.get('confirmemail').value; // get password from our confirmPassword form control
    // compare is the password math
    if (email !== confirmemail) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmemail').setErrors({ confirmemail: true });
    }
  }

  static HasNumber(control: FormControl) {
    const hasNumeric = /[0-9]+/.test(control.value);

    return hasNumeric ? { TextHasNumber: true } : null;

  }
}
