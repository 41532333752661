import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatStepperModule, MatFormFieldModule, MatInputModule, MatIconModule, MatDatepickerModule, MatCheckboxModule } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './Sys/SetUp/register/register.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GalleriaModule } from 'primeng/galleria';
import { DropdownModule } from 'primeng/dropdown';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LoginComponent } from './Sys/SetUp/login/login.component';
import { TopNavBarComponent } from './Sys/SetUp/top-nav-bar/top-nav-bar.component';
import { LandingComponent } from './Sys/SetUp/landing/landing.component';
import { AddMSQComponent } from './Sys/Operation/add-msq/add-msq.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ViewMCQComponent } from './Sys/Operation/view-mcq/view-mcq.component';
import { CourcesViewComponent } from './Sys/Operation/cources-view/cources-view.component';
import { ListComponent } from './Sys/SetUp/list/list.component';
import { DetailsComponent } from './Sys/SetUp/details/details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CKEditorModule } from 'ng2-ckeditor';

import { BasicInterceptor } from './Sys/Services/Users/BasicInterceptor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GuardService } from './Sys/Services/Users/guard.service';
import { DoResetPasswordComponent } from './Sys/SetUp/do-reset-password/do-reset-password.component';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { MediaComponent } from './Sys/SetUp/media/media.component';
import { CalenderViewComponent } from './Sys/Operation/calender-view/calender-view.component';
import { ContactusComponent } from './Sys/SetUp/contactus/contactus.component';
import { CalenderComponent } from './Sys/SetUp/calender/calender.component';
import { McqOnlineListComponent } from './Sys/SetUp/mcq-online-list/mcq-online-list.component';
import { McqExamComponent } from './Sys/SetUp/mcq-exam/mcq-exam.component';
import { TableModule } from 'primeng/table';



export function HttpLoaderFactory(http: HttpClient) {
  //return new TranslateService(http);
  //return new TranslateHttpLoader(http, '../assets/i18n/', '.json?v=1');
  //return new TranslateService(http);

}

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    TopNavBarComponent,
    LandingComponent,
    AddMSQComponent,
    ViewMCQComponent,
    CourcesViewComponent,
    ListComponent,
    DetailsComponent,
    DoResetPasswordComponent,
    MediaComponent,
    ContactusComponent,
    CalenderComponent,
    McqOnlineListComponent,
    McqExamComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MatFormFieldModule,
    MatStepperModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule, MatCheckboxModule,
    NgbModule,
    ConfirmDialogModule,
    GalleriaModule,
    ToastModule,
    CKEditorModule,
    TableModule,
    DialogModule, ScrollToModule.forRoot()
  ],
  exports: [
    TopNavBarComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [GuardService, MessageService, ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
