import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ListReult } from '../../Models/Common/ListResults';
import { TranslateMdl } from '../../Models/Translate.model';

@Injectable({
  providedIn: 'root'
})
export class TranslateServiceAPI {

  Url: string;
  constructor(protected _http: HttpClient) {
    this.Url = `${environment.baseUrl}${`Dictionary/`}`
  }
 
  getTranslation(langKey: string): Observable<any> {
    return this._http.get<ListReult<TranslateMdl>>(`${this.Url}HomeTranslate/${langKey}`);
  }
}
