import { Component, EventEmitter, OnInit, ViewChild, HostListener, ElementRef, Output, Host, Directive, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ActionFilter } from '../../Models/Common/ActionFilter';
import { HomeModel } from '../../Models/Home/Home';
import { CommonService } from '../../Services/Common/common.service';
import { EventsService } from '../../Services/EventsService/events.service';
import { HomeService } from '../../Services/Home/home.service';
import { MangeNewsService } from '../../Services/News/mange-news.service';
import { ContactUsService } from '../../Services/ContactUs/contact-us.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})

export class LandingComponent implements OnInit {

  constructor(el: ElementRef
    , private HomeService: HomeService
    , private route: ActivatedRoute
    , public CommonService: CommonService,
    private sanitizer: DomSanitizer ,private ContactUsService: ContactUsService, ) { }
 
  ListNews: HomeModel[] = [];
  Events: HomeModel[] = [];
  
  langKey = this.route.snapshot.paramMap.get("langKey")??"en";
  NewsDone = false;
  EventsDone = false;

  images: any[];
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];



  VideoUrl:any="";
  ngOnInit() {
    let v = this.CommonService.CurrentLang;
    this.GetCenterParts();
    //this.GetEvents();
    //this.VideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.translate.instant('library'));
    this.ConatactUsForm = new FormGroup({
      name: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      subject: new FormControl("", Validators.required),
      Description: new FormControl("", Validators.required)
    })
  }

  GetCenterParts() {
   
    this.NewsDone = false;
    let filter: ActionFilter =
    {
      
      getRows: 20,
      pageNumber: 0,
      langKey: this.langKey
    };

    let index = 1;
    if (this.langKey == "en")
      index = 1;
    if (this.langKey == "ar")
      index = 2;
    if (this.langKey == "fn")
      index = 3;
    this.HomeService.GetCenterPartSimple(index.toString()).subscribe(res => {
      this.ListNews = res.list;
      //this.ListNews.forEach(x => {
      //  x.link = this.CommonService.GetNewsLink(this.langKey, x);
      //});
      debugger;
      this.NewsDone = true;
    });
  }
  //GetEvents() {
  //  this.EventsDone = false;
  //  let filter: ActionFilter =
  //  {
  //    getRows: 20,
  //    pageNumber: 0,
  //    langKey: this.langKey
  //  };
  //  this.HomeService.GetEventsHomePage(filter).subscribe(res => {
  //    this.Events = res.list;
  //    this.Events.forEach(x => {
  //      x.link = this.CommonService.GetEventsLink(this.langKey, x);
  //    });

      
  //    this.EventsDone = true;
  //  });
  //}

  get activeIndex(): number {
    return this._activeIndex;
  }

  set activeIndex(newValue) {
    if (this.images && 0 <= newValue && newValue <= (this.images.length - 1)) {
      this._activeIndex = newValue;
    }
  }

  _activeIndex: number = 2;
  next() {
    this.activeIndex++;
  }

  prev() {
    this.activeIndex--;
  }

  ConatactUsForm: FormGroup;
  @ViewChild("submitBtn") SubmitBtn;
  ErrorMessage = "";
  ShowError = false;
  ShowGood = false;
  Submit() {
    debugger;
    this.ConatactUsForm.markAllAsTouched();
    if (this.ConatactUsForm.valid) {
     
      this.ContactUsService.PostContactUs(this.ConatactUsForm.value).subscribe(res => {
        this.SubmitBtn.nativeElement.disabled = false;
        if (res.success) {
          this.ShowGood = true;
          this.ErrorMessage="Your Message Is Sent"
        } else {
          this.ShowError = true;
          this.ErrorMessage = this.CommonService.CurrentLang.ContactUsError;
        }
      })
    }
  }


}
