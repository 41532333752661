import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionFilter } from '../../Models/Common/ActionFilter';
import { HomeModel } from '../../Models/Home/Home';
import { CommonService } from '../../Services/Common/common.service';
import { HomeService } from '../../Services/Home/home.service';
import { MangeNewsService } from '../../Services/News/mange-news.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import '@angular/localize/init';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  constructor( private route: ActivatedRoute
    , private CommonService: CommonService
    , private sanitized: DomSanitizer
    , private router: Router
    , private HomeService: HomeService) { }
  langKey = this.route.snapshot.paramMap.get("langKey") ?? "en";
  Page = this.route.snapshot.paramMap.get("Page");
  Value = this.route.snapshot.paramMap.get("Value");
  Done = false;
  List: HomeModel[] = [];

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
  DisplayCrausl = true;

  ngOnInit(): void {
    debugger;
    try {
      if (this.router.url.split("/")[2] == 'search') {
        this.Page = "search";
      }

    } catch {

    }
    this.GetList();
  }
  GetList() {
    if (this.PagingPage == 0) {
      this.First = 1;
    } else {
      this.First = (this.PagingPage * this.Rows) + 1;
    }

    

    this.CommonService.GetList(this, this.PagingPage, this.Rows, (res) => {
      if (this.List[0].photoPath.indexOf('#') > -1) {
        this.DisplayCrausl = false;
      }
      this.CalcHowManyPages(res);
      this.Total = res.total;
      let ThisMyPage = this.PagingPage + 1;

      this.Last = ThisMyPage * this.Rows;
      if (this.Last > this.Total) {
        this.Last = this.Total;
      }
    });
  }


  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }


  Rows: number = 10;
  PagingPage: number = 0;
  Pages: number[] = [];
  Total: number=0;
  Last: number = 0;
  First: number = 0;
  CalcHowManyPages(result: HomeModel) {
    this.Pages = [];

    let HasFraction = 0;
    try {
      HasFraction = parseInt(
        (parseInt((result.total / this.Rows).toString().split(".")[1])).toString()[0]
      );
    } catch (err) {

    }
    let PageNumbers = Math.round(result.total / this.Rows);
    if (HasFraction < 5) {
      PageNumbers += 1;
    }
    for (let i = 0; i < PageNumbers; i++) {
      this.Pages.push(i);
    }
    if (this.Last > this.Total) {
      this.Last = this.Total;
    }
  }


  Prev() {
    if (this.PagingPage > 0) {
      this.PagingPage -= 1;
      this.ChangePage(this.PagingPage);
    }
  }
  Next() {
    if (this.PagingPage < this.Pages.length) {
      this.PagingPage += 1;
      this.ChangePage(this.PagingPage);
    }
  }
  FirstPage() {
    this.ChangePage(0);
  }
  LastPage() {
    this.ChangePage(this.Pages.length - 1);
  }
  ChangePage(ThisPage) {
    this.PagingPage = ThisPage;
    if (this.PagingPage >= this.Pages.length) {
      this.PagingPage = this.Pages.length - 1;
    }
    this.GetList();
  }
}
