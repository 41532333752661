import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calender-view',
  templateUrl: './calender-view.component.html',
  styleUrls: ['./calender-view.component.css']
})
export class CalenderViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
