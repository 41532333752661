<app-top-nav-bar></app-top-nav-bar>
<p-progressSpinner *ngIf="Load"></p-progressSpinner>

<section id="hero">
  <div class="hero-container mb-5" style="top: 15vh;" data-aos="zoom-in" data-aos-delay="100">
    <section class="mx-auto mb-5 container">

      <div class="content">
          <section class="container" *ngIf="items.length!=0&&!ShowResult">
            <div class="row">
              <button class="btn btn-success" (click)="Submit()" [disabled]="Submited">Send</button>
            </div>
            <div class="row">
              <div class="col-6">
                Remaining Time
                {{strCurrentTime}}
              </div>
            </div>
            <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
            <div class="row" *ngFor="let Question of mcq; index as i;">
              <div class="card-body" *ngIf="activeIndex==i">
                <div class="d-flex justify-content-between text-secondary" style="font-style:italic">
                  <label><i class="fas fa-comment-dots"></i> Question {{i+1}}</label>
                  <div class="card-title ">Choose The Right Answer</div>
                </div>
                <h4 class="mt-3 mb-3 col-12" style="color:#000000;">{{Question.question}}</h4>
                <ol type="1" class="list-group">
                  <li *ngFor="let answer of Question.answers; index as ii;" (click)="SetStudentAnswer(answer,Question.question,$event)"
                      class="p-0 list-group-item list-group-item-action pointer-event">
                    <label class="d-block p-2 btn" [ngClass]="{'SelectedLabel':answer.selected}">  {{answer.answer}}</label>
                  </li>
                </ol>
              </div>
            </div>
            <div style="z-index:99999999;">
              <button class="btn btn-info pull-right" *ngIf="activeIndex!=items.length-1" (click)="activeIndex=activeIndex+1">Next</button>
              <button class="btn btn-success" *ngIf="activeIndex==items.length-1" (click)="Submit()" [disabled]="Submited">Send</button>
              <button class="btn btn-primary pull-left mx-4" *ngIf="activeIndex>0" (click)="activeIndex=activeIndex-1">Previous</button>
            </div>
          </section>
          <section class="container" *ngIf="ShowResult">
            <div class="set-size charts-container">
              <div class="pie-wrapper progress-{{ResultClass}}">
                <span class="label"> {{Result}}<span class="smaller">%</span></span>
                <div class="pie">
                  <div class="left-side half-circle"></div>
                  <div class="right-side half-circle"></div>
                </div>
              </div>
            </div>
          </section>
      </div>
      <p-toast></p-toast>

    </section>
  </div>


</section><!-- End Hero Section -->
