import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { ActionFilter, CenterActionFilter, ordertype } from '../../Models/Common/ActionFilter';
import { CenterPartsEnum } from '../../Models/Home/CenterParts';
import { HomeModel } from '../../Models/Home/Home';
import { TranslateMdl } from '../../Models/Translate.model';
import { HomeService } from '../Home/home.service';
import { MediaService } from '../Media/media.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private HomeService: HomeService,
    private MediaService: MediaService) { }

  GetNewsLink(LangKey, NewsHome: HomeModel) {
    return `/${LangKey}/news/${NewsHome.link}`
  }
  GetEventsLink(LangKey, EventsHome: HomeModel) {
    return `/${LangKey}/events/${EventsHome.link}`
  }
  GetMediaLink(LangKey, EventsHome: HomeModel) {
    return `/${LangKey}/media/${EventsHome.link}`
  }
  
  GetCenterLink(LangKey,center, EventsHome: HomeModel) {
    return `/${LangKey}/${center}/${EventsHome.link}`
  }
  GetList(_this, Page, Rows, CallBack = null) {
    debugger;
    _this.Done = true;
    let filter: ActionFilter =
    {
      getRows: Rows,
      pageNumber: Page,
      langKey: _this.langKey
    };

    if (_this.Page == "news") {
      this.HomeService.GetNewsHomePage(filter).subscribe(res => {
        _this.List = res.list;
        _this.List.forEach(x => {
          x.link = this.GetNewsLink(_this.langKey, x);
        });
        _this.Done = true;
        if (CallBack != null) {
          CallBack(res);
        }
      });
    }  else if (_this.Page == "events") {
      this.HomeService.GetEventsHomePage(filter).subscribe(res => {
        _this.List = res.list;
        _this.List.forEach(x => {
          x.link = this.GetEventsLink(_this.langKey, x);
        });
        _this.Done = true;
        if (CallBack != null) {
          CallBack(res);
        }
      });
    } else if (_this.Page == "media") {
      this.HomeService.GetPhotoGaleryHeadNames(filter).subscribe(res => {
        _this.List = res.list;
        _this.List.forEach(x => {
          x.link = this.GetMediaLink(_this.langKey, x);
        });
        _this.Done = true;
        if (CallBack != null) {
          CallBack(res);
        }
      });
    } else if (_this.Page == "search") {
      this.HomeService.Search(filter.langKey, _this.Value).subscribe(res => {
        _this.List = res.list;
        _this.Done = true;
        if (CallBack != null) {
          CallBack(res);
        }
      });
    } else if (this.InCenterPart(_this.Page)) {
      let Centerfilter: CenterActionFilter =
      {
        getRows: Rows,
        pageNumber: Page,
        langKey: _this.langKey,
        centerPartsEnum: CenterPartsEnum[_this.Page as keyof typeof CenterPartsEnum]
      };
      this.HomeService.GetCenterPart(Centerfilter).subscribe(res => {
        _this.List = res.list;
        _this.List.forEach(x => {
          x.link = this.GetCenterLink(_this.langKey, _this.Page, x);
        });
        _this.Done = true;
        if (CallBack != null) {
          CallBack(res);
        }
      });
    }
  }
  GetDetails(_this, CallBack = null) {
    if (_this.Page == "news") {
      this.HomeService.GetNewsDetails(_this.langKey,_this.Id).subscribe(res => {
        _this.Elm = res.elm;
        _this.Done = true;
        if (CallBack != null) {
          CallBack();
        }
      });
      
    } else if (_this.Page == "events") {
      this.HomeService.GetEventsDetails(_this.langKey, _this.Id).subscribe(res => {
        _this.Elm = res.elm;
        _this.Done = true;
        if (CallBack != null) {
          CallBack();
        }
      });
    } else if (_this.Page == "media") {
      this.HomeService.GetGaleryPhotos(_this.langKey,_this.Id).subscribe(res => {
        _this.Elm = res.elm;
        _this.Done = true;
        if (CallBack != null) {
          CallBack();
        }
      });
    } else if (this.InCenterPart(_this.Page)) {
      this.HomeService.GetCenterPartDetails(_this.langKey, _this.Id).subscribe(res => {
        _this.Elm = res.elm;
        _this.Done = true;
        if (CallBack != null) {
          CallBack();
        }
      });
    } 
  }
  InCenterPart(value) {
    let Test = false;
    for (var enumMember in CenterPartsEnum) {
      var isValueProperty = parseInt(enumMember, 10) >= 0
      if (isValueProperty) {
        return true;
      }
    }
    return Test;
  }
  GoToInter(Router: Router, link, Interv = 2000) {
    try {
      setTimeout(function () {
        Router.navigate([link]);
      }, Interv)
    } catch (err) {
      console.log(err);
    }
  }
  public ckeConfig = {
    allowedContent: false,
    forcePasteAsPlainText: false,
    extraPlugins: 'divarea',
    font_names: 'Arial;Times New Roman;Verdana',
    contentsLangDirection: "ltr",
    language: 'en',
    autoParagraph: false,
    toolbar: [
      { name: 'document', groups: ['mode'], items: ['Source'] },
      {
        name: 'editing', groups: ['find', 'selection', 'spellchecker'],
        items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt']
      },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'] },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      '/',
      { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'tools', items: ['Maximize'] },
    ]
  }

  SetTableFilter(filter: ActionFilter, TableEvent: LazyLoadEvent, Filter: string = null) {

    filter.pageNumber = TableEvent.first / TableEvent.rows;
    filter.getRows = TableEvent.rows;
    filter.orderBy = TableEvent.sortField;
    filter.orderType =
      TableEvent.sortOrder == undefined || TableEvent.sortOrder == 1
        ? ordertype.asc
        : ordertype.descending;
    filter.filter = Filter;
  }
  public CurrentLang: TranslateMdl = new TranslateMdl();
}
