import { FileUpload } from "./Common/FileUpload";

export class Student {
  userId: number = -1;
  fname: string="";
  midname: string = "";
  lastname: string = "";
  genderid: number = 1;
  counteryid: number = 53;
  zipcode: string = "";
  birthdate: string; /*"2021-09-19"*/
  officeemail: string;
  personalemail: string;
  officephone: string;
  homephone: string;
  mobilephone: string;
  passportnumber: string;
  issuedby: string;
  passportname: string;
  issuedate: string;
  expiredate: string;
  depatureairport: string;

  emergencycontct: string;
  emergencyreklation: string;
  emergencytel: string;
  file1: string;
  file2: string;
  file3: string;
  files: FileUpload[];
  isUser: boolean = true;
  skills: string;

}
