<app-top-nav-bar></app-top-nav-bar>

<div class="content">



  <section id="hero" class="m-auto">
    <div style="top: 15vh;" class="hero-container" data-aos="zoom-in" data-aos-delay="100">
      <section class="col-12 row MyItemCtr" *ngIf="ShowContactUs">
        <form [formGroup]="ConatactUsForm">
          <div>
            <div *ngIf="ShowError" class="text-danger">
              {{ErrorMessage}}
            </div>
            <div class="form-group form-row">
              <label class="col-12 text-white">Name</label>
              <input  class="form-control text-black" formControlName="name" />
              <p class="text-danger m-0" *ngIf="ConatactUsForm.get('name').invalid && ConatactUsForm.get('name').touched">
                <span class=" " *ngIf="ConatactUsForm.get('name').errors.required">{{CommonService.CurrentLang.Requierd}}</span>
              </p>
            </div>
            <div class="form-group form-row">
              <label class="text-white col-12">Email</label>
              <input type="email" class="form-control text-black" formControlName="email"  ngModel email>
              <p class="text-danger m-0" *ngIf="ConatactUsForm.get('email').invalid && ConatactUsForm.get('email').touched">
                <span class=" " *ngIf="ConatactUsForm.get('email').errors.required
                      || ConatactUsForm.get('email').errors.email">{{CommonService.CurrentLang.Requierd}}</span>
                <span class=" " *ngIf="">{{CommonService.CurrentLang.Requierd}}</span>
              </p>
            </div>
            <div class="form-group form-row">
              <label class="text-white col-12">Subject</label>
              <input class="form-control text-black" formControlName="subject" />
              <p class="text-danger m-0" *ngIf="ConatactUsForm.get('subject').invalid && ConatactUsForm.get('subject').touched">
                <span class=" " *ngIf="ConatactUsForm.get('subject').errors.required">{{CommonService.CurrentLang.Requierd}}</span>
              </p>
            </div>
            <div class="form-group form-row">
              <label class="text-white col-12">Message</label>
              <ckeditor class="col-12" [config]="Configuration" formControlName="message">
              </ckeditor>
            </div>
            <div class="form-group">
              <button class="btn btn-success" (click)="Submit()" #submitBtn>Submit</button>
            </div>
          </div>
        </form>
      </section>
      <section class="col-12 row MyItemCtr" *ngIf="!ShowContactUs">
        <h1 class="text-white text-center">
          {{CommonService.CurrentLang.ContactUsSend}}
        </h1>
      </section>

    </div>
  </section>
</div>
