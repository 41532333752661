export enum CenterPartsEnum {
  LectureHalls = 1,
  Library = 2,
  Services = 3,
  RuggedDrivingRanges = 4,
  inhibitions = 5,
  FootBall = 6,
  Gym = 7,
  Vision = 8,
  Mission = 9,
  Facilities = 10,
  FactFiguers = 11,
  Partners = 12,
  Researches = 13,
  Studies = 14,
  Newsletter = 15,
  PressRealese = 16,
}
