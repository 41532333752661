import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CommonService } from '../../Services/Common/common.service';
import { CustomValidators } from '../../Services/Common/CustomValidator';
import { UsersService } from '../../Services/Users/users.service';

@Component({
  selector: 'app-do-reset-password',
  templateUrl: './do-reset-password.component.html',
  styleUrls: ['./do-reset-password.component.css'],
  providers: [MessageService]
})
export class DoResetPasswordComponent implements OnInit {

  constructor(public UsersService: UsersService,
    public CommonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
  ) { }
  ForgetForm: FormGroup;

  Email: string = this.route.snapshot.paramMap.get('Email');
  Token: string = this.route.snapshot.paramMap.get('Token');
  @ViewChild("ResetBtn") ResetBtn: ElementRef;

  ngOnInit(): void {
    this.ForgetForm = this.fb.group({
      password: new FormControl('',
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
        ]),
      confirmpassword: new FormControl("", Validators.required),
    },
      {
        // check whether our password and confirm password match
        validator: [CustomValidators.passwordMatchValidator]
      })
  }
  ThankYouModel = false;
  Reset() {
    this.ForgetForm.markAllAsTouched();
    if (this.ForgetForm.valid) {
      this.ResetBtn.nativeElement.disabled = true;
      this.UsersService.DoResetPassword({ email: this.Email, token: this.Token, newPassword: this.ForgetForm.controls["password"].value }).subscribe(res => {
        this.ResetBtn.nativeElement.disabled = false;
        this.messageService.add({
          severity: res.notificationType,
          summary: res.notificationType,
          detail: res.msg
        });
        if (res.success) {
          this.CommonService.GoToInter(this.router, '/');
        }
      })
    }

  }

}
