import { Question } from './Question.model';

export class Answer {

  Answer: string = "no titel";
  right: boolean = false;
  //QuestionId: string = "";
  //Question: Question = new Question();
  
}
