import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APICallsService } from '../../Services/apicalls.service';

@Component({
  selector: 'app-view-mcq',
  templateUrl: './view-mcq.component.html',
  styleUrls: ['./view-mcq.component.css']
})
export class ViewMCQComponent implements OnInit {


  AnswerIcon: any = ["A", "B", "C", "D"];
  result: boolean = false;
  //mcq: any = [
  //  {
  //    "question": " About how many peacekeepers are deployed to 18 hotspots around the world?",
  //    "answers": ["50,000", "100,000", "200,000", "1.1 million"],
  //    "multiAnswers": false, "correctAnswer": [2], "UserAnswer": []
  //  },
  //  {
  //    "question": "Which is the top troop contributing country to UN peacekeeping?",
  //    "answers": [" Canada ", "Answer 2 2", "Answer 2 3", "Answer 2 4"], "multiAnswers": true, "correctAnswer": [0, 4],
  //    "UserAnswer": []
  //  },
  //  { "question": " How many Americans are serving in UN peacekeeping missions, as of April?", "answers": ["Answer 3 1", "Answer 3 2", "Answer 3 3", "Answer 3 4"], "multiAnswers": false, "correctAnswer": [1], "UserAnswer": [] },
  //  { "question": "There are 17 Peacekeeping missions around the world. About how much do these missions cost member states per year?", "answers": ["Answer 4 1", "Answer 4 2", "Answer 4 3", "Answer 4 4"], "multiAnswers": false, "correctAnswer": [2], "UserAnswer": [] },
  //  { "question": "There have been 67 peacekeeping missions since 1948. What is the oldest current standing peackeeping mission?", "answers": ["Answer 5 1", "Answer 5 2", "Answer 5 3", "Answer 5 4"], "multiAnswers": false, "correctAnswer": [3], "UserAnswer": [] },
   
  //];

  mcq: any[];

  constructor(public router: Router, public API: APICallsService) {

    this.API.GetQuez().subscribe(
      Data => {
        console.log('test');
        this.mcq = Data;


      },
      err => {
        console.log(err);
      });}

  ngOnInit() {

   

  }


  setUserAnswer(i: any, ii: any, event: any) {
    this.result = false;
    if (event.target.type == 'radio') {
      for (let i = 0; i < this.mcq.length; i++) { this.mcq[i]["UserAnswer"] = []; }
    }
    if (event.target.checked) {
      console.log('add');
      this.mcq[i]["UserAnswer"].push(ii++);
    } else {
      console.log('remove');
      this.mcq[i]["UserAnswer"].splice(this.mcq[i]["UserAnswer"].indexOf(ii++), 1);
    }
    console.log(this.mcq[i]["UserAnswer"]);
    console.log(this.mcq[i]["correctAnswer"]);
    //console.log(this.mcq[i]["correctAnswer"].indexOf(this.mcq[i]["UserAnswer"][0]) != -1)
    this.isCorrect(i);
  }

  isCorrect(i: any) {
    if (this.mcq[i]["correctAnswer"].indexOf(this.mcq[i]["UserAnswer"][0]) != -1) {
      this.mcq[i]["evaluation"] = 'success';
    } else {
      this.mcq[i]["evaluation"] = 'no success';
    }
  }

  setResult() {
    this.result = true;
  }

  showREsult() {
    console.log(this.mcq);

    if (this.mode(this.mcq[0].answers === "true"))
      alert(true);
    else
      alert(false);


    if (this.mode(this.mcq[1].answers === "true"))
      alert(true);
    else
      alert(false);


    if (this.mode(this.mcq[2].answers === "true"))
      alert(true);
    else
      alert(false);
   // this.router.navigate(['']);
  }


  mode(arr) {
  return arr.sort((a, b) =>
    arr.filter(v => v === a).length
    - arr.filter(v => v === b).length
  ).pop();
}

}
