import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Student } from '../Models/Student.model';
import { Question } from '../Models/Question.model';
import { environment } from '../../../environments/environment';
import { SaveResult } from '../Models/Common/SaveResult';


@Injectable({
  providedIn: `root`
})
export class APICallsService {

  Url: string;
  constructor(protected http: HttpClient) {
    this.Url = `${environment.baseUrl}`
  }
  Qur: string = "";
  GetCounty() {
    this.Qur = `${this.Url}user/GetCounters`;
    return this.http.get<any>(this.Qur);

  }

  GetQuez() {
    this.Qur = `${this.Url}Questions/GetQuestions/`;
    return this.http.get<any>(this.Qur);

  }

  RegStudent(NewStud: Student,Attach:any[]) {
    this.Qur = `${this.Url}Accounts/Registration`;
    return this.http.post<SaveResult>(this.Qur, NewStud);
  }

  SaveQuestion(NewStud: Question) {
    this.Qur = `${this.Url}Questions/AddQuestions`;
    return this.http.post<any>(this.Qur, NewStud);
  }



  SaveImg(img: any) {
    try {
      const formData = new FormData();
      formData.append(`image`, img[0]);
      this.Qur = `http://technicalcls.reflection-eg.com/api/MImagesController/UploadImage?ClntCd=2`;
      return this.http.post<string>(this.Qur, formData);
    } catch (err) {

    }
    
  }

  

  

}
