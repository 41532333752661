<app-top-nav-bar></app-top-nav-bar>



<section id="hero">
  <div class="hero-container mb-5" style="top: 15vh;"  data-aos-delay="100">
    <section class="mx-auto mb-5 container" style="height:100%;">
      
        <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
          <!--<div class="row row-striped">
            <div class="col-2 text-right">
              <h1 class="display-4"><span class="badge badge-secondary">23</span></h1>
              <h2>OCT</h2>
            </div>
            <div class="col-10">
              <h3 class="text-uppercase"><strong>Ice Cream Social</strong></h3>
              <ul class="list-inline">
                <li class="list-inline-item"><i class="fa fa-calendar-o" aria-hidden="true"></i> Monday</li>
                <li class="list-inline-item"><i class="fa fa-clock-o" aria-hidden="true"></i> 12:30 PM - 2:00 PM</li>
                <li class="list-inline-item"><i class="fa fa-location-arrow" aria-hidden="true"></i> Cafe</li>
              </ul>
              <p>Lorem ipsum dolsit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
          </div>-->
          <div class="container">
            <h1>Our Agenda 2022</h1>
            <br />
            <div *ngFor="let C Of Calendar" style="min-width:900px;">
              <div class="row row-striped">
                <div class="col-2 text-right">
                  <h1 class="display-4"><span class="badge badge-secondary">{{C.topicDay}}</span></h1>
                  <h2>{{C.topicMonth}}</h2>
                </div>
                <div class="col-10">
                  <h4 class="text-uppercase" style="text-align:left;color:white"><strong>{{C.title}}</strong></h4>
                  <ul class="list-inline">
                    <li class="list-inline-item"><i class="fa fa-calendar-o" aria-hidden="true"></i> {{C.topicWeekDayHour}}</li>
                  </ul>
                  <p style="text-align: left; color: wheat;font-size:x-large">{{C.description}}</p>
                </div>
              </div>
            </div>

          </div>
        </div>




      
      <!--End Hero Section-->
   </section>
    </div>
  </section>

