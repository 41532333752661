<!-- ======= Hero Section ======= -->
<section id="hero">
  <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
    <!--<h1>
      Welcome to Egyptian Peacekeeping Operations Training Center

    </h1>-->
    <!--<h2>We are team of talented Leaders </h2>-->
    <a class="btn-get-started">{{CommonService.CurrentLang.Home}}</a>
  </div>
</section><!-- End Hero Section -->

<main id="main">

  <!-- ======= About Section ======= -->
  <section id="about">
    <div class="container" data-aos="fade-up">
      <div class="row about-container">

        <div class="col-lg-6 content order-lg-1 order-2">
          <h2 class="title">{{CommonService.CurrentLang.A1Title}}</h2>
          <p>
            {{CommonService.CurrentLang.A1}}
          </p>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="bi bi-briefcase"></i></div>
            <h4 class="title"><a>{{CommonService.CurrentLang.A2Title}}</a></h4>
            <p class="description"> {{CommonService.CurrentLang.A2}}</p>
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="bi bi-card-checklist"></i></div>
            <h4 class="title"><a>{{CommonService.CurrentLang.A3Title}}</a></h4>
            <p class="description">
              {{CommonService.CurrentLang.A3}}
            </p>
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
            <div class="icon"><i class="bi bi-binoculars"></i></div>
            <h4 class="title"><a>{{CommonService.CurrentLang.A4Title}}</a></h4>
            <p class="description">{{CommonService.CurrentLang.A4}}</p>
          </div>
          <a href="./assets/Arabic-Brochure.pdf" download  *ngIf="langKey==='ar'"><i style="cursor:pointer;font-size:larger;background-color:lightgray">{{CommonService.CurrentLang.Services}}</i></a>
          <a href="./assets/Brochure- Francais.pdf" download style="cursor:pointer;" *ngIf="langKey==='fn'">{{CommonService.CurrentLang.Services}}</a>
          <a href="./assets/English- Brochure.pdf" download style="cursor:pointer;" *ngIf="langKey==='en'">{{CommonService.CurrentLang.Services}}</a>
        </div>

        <div class="col-lg-6 background order-lg-2 order-1" data-aos="fade-left" data-aos-delay="100">
          <div style="margin-top:25%;">

            <iframe width="560" height="315" src="https://www.youtube.com/embed/ilyEu-Gkh7o" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen *ngIf="langKey==='ar'"></iframe>

            <iframe width="560" height="315" src="https://www.youtube.com/embed/ilyEu-Gkh7o" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen *ngIf="langKey==='en'"></iframe>

            <iframe width="560" height="315" src="https://www.youtube.com/embed/ilyEu-Gkh7o" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen *ngIf="langKey==='fn'"></iframe>
            <!--<iframe src="https://drive.google.com/file/d/1DW6GOlVN7-2STLKJ0KbzCdBZHWaIoX05/preview" width="640" height="480" allow="autoplay" *ngIf="langKey==='fn'"></iframe>
  <iframe src="https://drive.google.com/file/d/1DW6GOlVN7-2STLKJ0KbzCdBZHWaIoX05/preview" width="640" height="480" allow="autoplay" *ngIf="langKey==='en'"></iframe>-->
          </div>
        </div>
      </div>

    </div>
  </section><!-- End About Section -->
  <!-- ======= Call To Action Section ======= -->
  <section id="call-to-action">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 text-center text-lg-start">
          <p-galleria [(value)]="ListNews" [responsiveOptions]="responsiveOptions"
                      panelWidth="500" panelHeight="313" showCaption="true" [numVisible]="5">
            <div class="p-py-2">
              <p-button type="button" icon="pi pi-plus" (click)="next()" styleClass="p-button-secondary p-mr-2"></p-button>
              <p-button type="button" icon="pi pi-minus" (click)="prev()" styleClass="p-button-secondary"></p-button>
            </div>
            <ng-template pTemplate="item" let-item>
              <div class="col-12">
                <img [src]="item.photoPath" style="width:100%;height:500px;display: block;" />
              </div>
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
              <div class="p-grid p-nogutter p-justify-center" style="overflow:hidden;">
                <img [src]="item.photoPath" style="width: 140px;height: 90px;overflow:hidden; " />
              </div>
            </ng-template>
            <ng-template pTemplate="caption" let-item>
              <h4 style="margin-bottom: .5rem; color: #ffffff; background-color: black; opacity: 0.8">{{item.title}}</h4>
              <p style="color: #ffffff; background-color: black; opacity: 0.8" [innerHtml]="item.shortDesc">{{item.shortDesc}}</p>
            </ng-template>
          </p-galleria>
        </div>
        <!--<div class="col-lg-3 cta-btn-container text-center">
          <a class="cta-btn align-middle" href="#">Call To Action</a>
        </div>-->
      </div>

    </div>
  </section><!-- End Call To Action Section -->
  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio" *ngIf="false">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <h3 class="section-title">Events</h3>
        <h3 class="section-title" *ngIf="Events.length===0">No Current Events</h3>
      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="100">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active">All</li>
            <li data-filter=".filter-1">Work Shop</li>
            <li data-filter=".filter-2">Conferences</li>
            <li data-filter=".filter-3">seminars</li>

          </ul>
        </div>
      </div>
      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
        <div *ngFor="let Event of Events" class="col-lg-4 col-md-6 portfolio-item filter-{{Event.eventType}}">
          <img style="width:380px;height:234px;" src="{{Event.photoPath}}" class="img-fluid" alt="">
          <div class="portfolio-info">
            <h4>{{Event.title}}</h4>
            <p [innerHTML]="Event.shortDesc"></p>
            <a [routerLink]="Event.link" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="{{Event.title}}"><i class="bx bx-plus"></i></a>
            <a [routerLink]="Event.link" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Portfolio Section -->
  <!-- ======= Team Section ======= -->
  <section id="team" *ngIF="false">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <h3 class="section-title">News</h3>
        <a [routerLink]="[langKey,'news']"><p class="section-description">Click More Details To Get All Our News</p></a>
      </div>
      <div class="row">
        <div *ngIf="!NewsDone" class="text-center">Loading....<i class="fa fa-spinner fa-pulse fa-3x text-center"></i></div>
        <div class="col-lg-3 col-md-6" *ngFor="let new of ListNews; let index = index">
          <div class="member" data-aos="fade-up" data-aos-delay="100">
            <div class="pic"><img style="width:380px;height:234px;" src="{{new.photoPath}}" alt=""></div>
            <h4><a [routerLink]="new.link">{{new.title}}</a></h4>
            <div class="MyText" [innerHTML]="new.shortDesc"></div>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Team Section -->
  <!-- ======= Contact Section ======= -->
  <section id="contact">
    <div class="container">
      <div class="section-header">
        <h3 class="section-title">{{CommonService.CurrentLang.ContactUs}}</h3>
        <!--<p class="section-description">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p>-->
      </div>
    </div>
    <!-- Uncomment below if you wan to use dynamic maps -->
   
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27627.74119847448!2d31.239228816027822!3d30.052126953408642!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583d05bdebd617%3A0x4cf366ea82745544!2sMinistry%20of%20Interior%20Affairs!5e0!3m2!1sen!2seg!4v1636458544426!5m2!1sen!2seg" width="100%" height="380" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-4">
          <div class="info">
            <div>
              <i class="bi bi-geo-alt"></i>
              <p>Egypt Cairo</p>
            </div>

          </div>
          <!--<div class="social-links">
            <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
            <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
            <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
            <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
            <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
          </div>-->
        </div>
        <div class="col-lg-5 col-md-8">
          <div class="form">
            <form [formGroup]="ConatactUsForm">
              <div *ngIf="ShowError" class="text-danger">
                {{ErrorMessage}}
              </div>
              <div *ngIf="ShowGood" class="text-success">
                {{ErrorMessage}}
              </div>
              <div class="form-group">
                <input type="text" name="name" class="form-control" formControlName="name" placeholder="Your Name" required>
              </div>
              <div class="form-group mt-3">
                <input type="email" class="form-control" name="email" formControlName="email" placeholder="Your Email" required>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" formControlName="subject" placeholder="Subject" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" formControlName="Description" required></textarea>
              </div>
             
              <div class="text-center"><button type="submit" (click)="Submit()" #submitBtn>Submit</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Contact Section -->
</main><!-- End #main -->
<!-- Vendor JS Files -->
<header id="header" class="fixed-top d-flex align-items-center header-transparent">
  <div class="container d-flex justify-content-between align-items-center">
    <div id="logo">
      <!--<a href="index.html"><img src="assets/img/Logo2.png" style="width:120px;height:120px" alt=""></a>-->
      <!-- Uncomment below if you prefer to use a text logo -->
      <!--<h1><a href="index.html">Regna</a></h1>-->
    </div>
    <app-top-nav-bar></app-top-nav-bar>
  </div>
</header><!-- End Header -->
<!--<app-register></app-register>-->
<!--<app-login></app-login>-->
<!--<app-landing></app-landing>-->
<!-- اكتب هنا الفوتر-->
<script src="assets/vendor/aos/aos.js"></script>
<script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
<script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
<script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
<script src="assets/vendor/php-email-form/validate.js"></script>
<script src="assets/vendor/purecounter/purecounter.js"></script>
<script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
<!-- Template Main JS File -->
<script src="assets/js/main.js"></script>
