import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { ActionFilter } from '../../Models/Common/ActionFilter';
import { NameId } from '../../Models/Common/NameId';
import { CommonService } from '../../Services/Common/common.service';
import { HomeService } from '../../Services/Home/home.service';

@Component({
  selector: 'app-mcq-online-list',
  templateUrl: './mcq-online-list.component.html',
  styleUrls: ['./mcq-online-list.component.css']
})
export class McqOnlineListComponent implements OnInit {

  constructor(private messageService: MessageService, public router: Router, public HomeService: HomeService,
    private CommonService: CommonService, private route: ActivatedRoute) { }

  langKey = this.route.snapshot.paramMap.get("langKey") ?? "en";
  //CourseId = Number(this.route.snapshot.paramMap.get("CourseId"));
  CoursesForm: FormGroup;
  loading = false;
  Subject: NameId[] = [];
  ngOnInit(): void {

  }
  Total;
  TableEvent: LazyLoadEvent;
  loadResult(event: LazyLoadEvent) {
    this.TableEvent = event;
    this.GetResult();
  }
  filter: ActionFilter = new ActionFilter();
  Filter;
  GetResult() {
    this.loading = true;

    this.CommonService.SetTableFilter(this.filter, this.TableEvent, this.Filter)

    this.HomeService
      .GetSubject(this.filter)
      .subscribe((resp) => {
        this.loading = false;
        this.Subject = resp.list;
        this.Total = resp.total;
      });

  }
  GoToExam(ExamTmplTbl) {
    this.router.navigate([this.langKey,'McqExam', ExamTmplTbl])
  }

}
