import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ElmResult } from '../../Models/Common/ElmResult';
import { NotifcationTypes } from '../../Models/Common/NotifcationTypes';
import { Questions } from '../../Models/MCQ/Student/Questions';
import { StudentAnswer, StudentAnswerCnt } from '../../Models/MCQ/Student/StudentAnswer';
import { QuestionsService } from '../../Services/Questions/questions.service';

@Component({
  selector: 'app-mcq-exam',
  templateUrl: './mcq-exam.component.html',
  styleUrls: ['./mcq-exam.component.scss']
})
export class McqExamComponent implements OnInit {



  result: boolean = false;

  mcq: Questions[] = [];
  StudentAnswers: StudentAnswer[] = [];
  items: MenuItem[] = [];
  activeIndex: number = 0;


  constructor(private messageService: MessageService, public router: Router, public Questions: QuestionsService
    , public route: ActivatedRoute) {


  }
  langKey = this.route.snapshot.paramMap.get("langKey") ?? "en";
  ThisExam = Number(this.route.snapshot.paramMap.get("ExamId"));
  RemainingTime: number;
  Load = true;
  currTime: number;
  strCurrentTime: string;
  obsTimer: Observable<number> = timer(1000, 1000);
  componentDestroyed$: Subject<boolean> = new Subject()

  ngOnInit() {
    this.Questions.GetExamSetting(this.ThisExam).subscribe(
      Data => {
        debugger;
        if (!Data.success) {
          this.messageService.add({ severity: NotifcationTypes.error, summary: NotifcationTypes.error, detail: Data.msg, life: 999999 });
          this.Load = false;
          return;
        }
        if (Data.success && Data.id != 0) {

          this.messageService.add({
            severity: NotifcationTypes.success,
            summary: NotifcationTypes.success,
            detail: Data.msg,
            life: 999999
          });
          if (Data.id != -1) {
            this.ShowResult = true;
            let _this = this;
          }

          let ThisResNumber = parseFloat(Data.msg.replace('%', ''));
          this.SetDegreeInt(ThisResNumber);
          //let Count = 0;
          //let ResInt = setInterval(function () {
          //  Count += 1;
          //  if (parseFloat(_this.Result) >= ThisResNumber) {
          //    if (parseFloat(_this.Result) > ThisResNumber) {
          //      _this.Result = parseFloat(Data.msg.replace('%', '')).toString();
          //    }
          //    clearInterval(ResInt);
          //  } else {
          //    _this.Result = Count.toString();
          //  }
          //}, 20)
          this.Load = false;
          return;
        }
        this.currTime = Data.elm.duration.totalSeconds;
        this.obsTimer
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(Time => {
            if (this.currTime <= 0) {
              this.Submit();

            } else {
              this.currTime -= 1;
              this.strCurrentTime = this.displayTime(this.currTime - 1)
            }
          });
        this.mcq = Data.elm.questions;
        this.mcq.forEach((x, index) => {
          this.items.push({
            label: 'Question ' + (index + 1),
            command: (event: any) => {
              this.activeIndex = index;
            }
          });
        });
        this.StudentAnswers = JSON.parse(localStorage.getItem("answers"));
        if (this.StudentAnswers == null) {
          this.StudentAnswers = [];
        } else {
          this.StudentAnswers.forEach(StudentAnswer => {
            this.mcq.find(x => x.answers.find(z => z.id == StudentAnswer.id)).answers.find(z => z.id == StudentAnswer.id).selected = true;
          })

        }
        this.Load = false;
      },
      err => {
        console.log(err);
      });
  }

 

  displayTime(ticksInSecs) {
    var ticks = ticksInSecs;
    var hh = Math.floor(ticks / 3600);
    var mm = Math.floor((ticks % 3600) / 60);
    var ss = Math.floor(ticks % 60);

    return (this.pad(hh, 2) + ":" + this.pad(mm, 2) + ":" + this.pad(ss, 2));
  }
  pad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }
  SetStudentAnswer(StudentAnswer: StudentAnswer, Question: string, event) {
    debugger;
    StudentAnswer.question = Question;
    if (this.StudentAnswers.findIndex(x => x.questionId == StudentAnswer.questionId) > -1) {
      this.StudentAnswers.splice(this.StudentAnswers.findIndex(x => x.questionId == StudentAnswer.questionId), 1);
    }
    StudentAnswer.answerId = StudentAnswer.id;
    this.StudentAnswers.push(StudentAnswer);
    this.mcq.find(x => x.answers.find(z => z.id == StudentAnswer.id)).answers.forEach(x => {
      x.selected = false;
    });
    this.mcq.find(x => x.answers.find(z => z.id == StudentAnswer.id)).answers.find(z => z.id == StudentAnswer.id).selected = true;
    localStorage.setItem("answers", JSON.stringify(this.StudentAnswers));
  }
  Submited = false;
  ShowResult: boolean = false;
  Result: string = "0";
  ResultClass: string = "0";
  Msg = ""
  Submit() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
    this.Submited = true;
    let Answers: StudentAnswerCnt =
    {
      examId: this.ThisExam,
      answers: this.StudentAnswers
    };
    this.Questions.SubmitAnswers(Answers)
      .subscribe(res => {
        if (res.success) {
          localStorage.setItem("answers", null);
          this.items.length = 0;
          if (res.id == 0) {
            this.ShowResult = true;
            this.SetDegreeInt(res.msg);
            this.messageService.add({
              severity: res.notificationType,
              summary: res.notificationType,
              detail: res.msg + "%"
            });
          }
          else if (res.id == -1) {
            this.messageService.add({
              severity: res.notificationType,
              summary: res.notificationType,
              detail: res.msg,
              life: 999999
            });
          }
        } else {
          localStorage.setItem("answers", null);

          this.messageService.add({ severity: NotifcationTypes.error, summary: NotifcationTypes.error, detail: res.msg, life: 999999 });
        }
      })
  }
  SetDegreeInt(Result) {
    let Count = 0;
    let ThisResNumber = parseFloat(Result);
    let _this = this;
    let ResInt = setInterval(function () {
      Count += 1;
      if (parseFloat(_this.Result) >= ThisResNumber) {
        if (parseFloat(_this.Result) >= ThisResNumber) {
          _this.Result = Result;
          _this.ResultClass = Math.round(Number(Result)).toString();
        }
        clearInterval(ResInt);
      } else {
        _this.Result = Count.toString();
        _this.ResultClass = Math.round(Number(Count.toString())).toString();
      }
    }, 20)
  }

}
