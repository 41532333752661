import { Directionality } from '@angular/cdk/bidi';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../Services/Users/users.service';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateServiceAPI } from '../../Services/Translation/translateAPI.service';
import { CommonService } from '../../Services/Common/common.service';


@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.css'],
  providers: [MessageService]
})
export class TopNavBarComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute, private router: Router, private dir: Directionality,
    private translate: TranslateServiceAPI, private elem: ElementRef, private messageService: MessageService
    , public CommonService: CommonService, public LoginService: UsersService,
    private primengConfig: PrimeNGConfig
  ) {
  
    this.IsDone = false;
    this.translate.getTranslation(this.langKey).subscribe(res => {
      this.CommonService.CurrentLang = res;
    });
    this.IsDone = true;

  }
  Show = false;
  langKey = this.route.snapshot.paramMap.get("langKey") ?? "en";
  IsLogin = sessionStorage.getItem("IsLogedIn");
  ngOnInit() {
    this.primengConfig.ripple = true;
    if (!this.route.snapshot.paramMap.get("langKey")) {
      this.router.navigate([window.location.pathname,'en']);
    }
    // this.translate.use(this.langKey);
    try {
      this.Flag = this.elem.nativeElement.querySelectorAll('.' + this.langKey + " img")[0].src;
    } catch (err) {

    }
    this.LoginForm = new FormGroup({
      username: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
    });
    this.ForgetForm = new FormGroup({
      Email: new FormControl("", [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')])
    })
  }
  /** Whether the widget is in RTL mode or not. */
  private isRtl: boolean;

  /** Subscription to the Directionality change EventEmitter. */
  private _dirChangeSubscription = Subscription.EMPTY;
  IsDone: Boolean= true;
  LoginForm: FormGroup;
  Flag;
  LoginVisable: boolean = false;

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }


  ChangeLang(lang, flag) {
   
    this.Flag = flag;
    let NewUrl = '';
    this.route.snapshot.paramMap.keys[0] = lang;
   

    this.route.snapshot.paramMap.keys.forEach((x, i) => {
      let ThisUrl = this.route.snapshot.paramMap.get(x);
      if (i == 0) {
        ThisUrl = lang;
      }
      NewUrl += '/' + ThisUrl;
    })
    if (this.router.url == '/' || this.router.url == "/ar" || this.router.url == "/en" || this.router.url == "/fn") {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/', lang]);
      });
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([NewUrl]));
    }
    this.langKey = lang;
    this.IsDone = false;
    this.translate.getTranslation(this.langKey).subscribe(res => {
      this.CommonService.CurrentLang = res;
    });
    this.IsDone = true;
    //this.Flag = this.elem.nativeElement.querySelectorAll('.' + this.langKey + " img")[0].src;

  }
  GoToCenterPart(Link) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/', this.langKey, Link]));
  }
  ngOnDestroy() {
    this._dirChangeSubscription.unsubscribe();
  }
  openNav() {
    debugger;
    this.elem.nativeElement.querySelectorAll("#Sidenav")[0].classList.add("show")
    this.elem.nativeElement.querySelectorAll("#Sidenav")[0].style.width = "250px"
  }

  closeNav() {
    this.elem.nativeElement.querySelectorAll("#Sidenav")[0].classList.remove("show")
    this.elem.nativeElement.querySelectorAll("#Sidenav")[0].style.width = "0px"
  }
  @ViewChild("LoginBtn") LoginBtn: ElementRef;
  Login() {
    this.LoginForm.markAllAsTouched();
    if (this.LoginForm.valid) {
      this.LoginBtn.nativeElement.disabled = true;
      this.LoginForm.value.isUser = true;
      this.LoginService.Login(this.LoginForm.value).subscribe(res => {
        this.messageService.add({
          severity: res.notificationType,
          summary: res.notificationType,
          detail: res.msg
        });
        if (res.success) {
          sessionStorage.setItem("token", res.token)
          sessionStorage.setItem("fullName", res.fullName)
          sessionStorage.setItem("IsLogedIn", "true")
          window.location.pathname = "/";
        }
        this.LoginBtn.nativeElement.disabled = false;
      }, (err) => {
        if (err.status != 401) {
          this.messageService.add({ severity: "error", summary: "error", detail: 'Error Connecting To Server' });
        }

        this.LoginBtn.nativeElement.disabled = false;
      });
    }
  }
  LogOut() {
    sessionStorage.setItem("token", null)
    sessionStorage.setItem("fullName", null)
    sessionStorage.setItem("IsLogedIn", "")
    window.location.pathname = "/";
  }
  ForgetForm: FormGroup;
  @ViewChild("ResetBtn") ResetBtn: ElementRef;
  ForgetModel = false;
  ThankYouModel = false;
  TogglePass = true;
  ResetPassword() {
    this.ForgetForm.markAllAsTouched();
    if (this.ForgetForm.valid) {
      this.ResetBtn.nativeElement.disabled = true;

      this.LoginService.ResetPassword(this.ForgetForm.controls["Email"].value).subscribe(res => {
        this.ResetBtn.nativeElement.disabled = false;
        this.messageService.add({
          severity: res.notificationType,
          summary: res.notificationType,
          detail: res.msg
        });
        if (res.success) {
          this.ForgetModel=false;
        }
      })
    }
  }
  ShowSearch: boolean = false;
  Search(value) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.langKey, 'search', value]);
    });
  }
}
